import React, { useContext, useEffect, useMemo } from 'react';

import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useTheme,
    Drawer,
    useMediaQuery,
    TextField,
    Divider,
    Menu,
    MenuItem,
    styled,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { deepOrange, deepPurple } from '@mui/material/colors';
import LyricsIcon from '@mui/icons-material/Lyrics';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Container, CotaninerListaGravacoes, Itens, ItemGravacao, Saldo } from './MenuLateral.styles';
import { useNavigate, useParams } from 'react-router-dom';
import { getAtendimentos, saveAtendimento } from '../../../services/atendimento';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { toast } from 'react-toastify';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMain } from '../../../contexts/MainContext';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../config/db';
import { removeTokenLocalStorage } from '../../../config/tokenManager';
import FormPagamento from '../../../elementos/formPagamento/formPagamento';
import MenuIcon from '@mui/icons-material/Menu';
import MenuTopMobile from '../menuTopMobile/MenuTopMobile';
import useMic from '../../../services/useMic';
import { CloseIcon } from '../../apresentacao/apresentacao.style';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
dayjs.locale('pt-br');
dayjs.extend(relativeTime);

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.primary.light,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.primary.light,
            maxWidth: 150,
            fontWeight: 'bold',
        },
    }),
);
function MenuLateral() {
    const { usuario, globalIsRecording, atendimentos, carregaAtendimentos, carregaUsuario } = useMain();

    const theme = useTheme();
    const param = useParams();
    const navegation = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openFormAssinaServico, setOpenFormAssinaServico] = React.useState(false);
    const audiosNaoEnviados = useLiveQuery(async () => {
        return await db.audios.where({ status: 'local' }).toArray();
    }, [param]);

    const isSmallScreen = useMediaQuery('(max-width:800px)');

    const [search, setSearch] = React.useState('');

    const atendimentosFiltrados = useMemo(() => {
        if (search === '') return atendimentos;

        const atendimentosData = atendimentos.filter((atendimento) => {
            const atendimentoDate = new Date(atendimento.dataCriacao);
            return atendimentoDate.toLocaleDateString().includes(search);
        });

        const atendimentosOrganizados = atendimentos
            .filter((atendimento) => atendimento.nome.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao));

        const atendimentosUnicos = atendimentos.filter(
            (atendimento) =>
                atendimentosOrganizados.find((i) => i.id === atendimento.id) ||
                atendimentosData.find((i) => i.id === atendimento.id),
        );
        return atendimentosUnicos;
    }, [search, atendimentos]);

    function selecionaItem(item) {
        if (globalIsRecording) return toast.error('Você não pode criar uma nova consulta enquanto estiver gravando');
        navegation(`/main/${item.id}`);
    }

    async function novoAtendimento() {
        try {
            if (usuario?.corporativo?.saldo != null) {
                if (usuario?.corporativo?.saldo == 0) {
                    toast.warning('Você não possui saldo suficiente para criar uma nova consulta');
                    toast.info('Para continuar utilizando o serviço, adquira mais saldo.');
                    setOpenFormAssinaServico(true);
                    return;
                }
            } else {
                if (usuario.saldo == 0) {
                    toast.warning('Você não possui saldo suficiente para criar uma nova consulta');
                    toast.info('Para continuar utilizando o serviço, adquira mais saldo.');
                    setOpenFormAssinaServico(true);
                    return;
                }
            }
            if (globalIsRecording) throw new Error('Você não pode criar uma nova consulta enquanto estiver gravando');
            setLoading(true);
            const atendimentoCriado = await saveAtendimento(
                `Consulta Nº ${atendimentos.length + 1}`,
                'Descrição da nova consulta',
            );
            await carregaAtendimentos();
            navegation(`/main/${atendimentoCriado.id}`);
        } catch (error) {
            toast.error('Erro ao criar nova consulta');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    function logout() {
        removeTokenLocalStorage();
        navegation('/');
    }

    if (loading) return <div>Carregando...</div>;

    return (
        <>
            <Drawer
                sx={{ width: globalIsRecording ? 'unset' : '300px' }}
                anchor={'left'}
                open={isSmallScreen ? openDrawer : true}
                onClose={() => setOpenDrawer(false)}
                variant={isSmallScreen ? 'temporary' : 'permanent'}
                PaperProps={{ sx: { borderRight: 'none' } }}
            >
                <Container style={{ display: globalIsRecording ? 'none' : 'flex' }}>
                    <Itens>
                        <div>
                            <Button variant="contained" fullWidth startIcon={<AddIcon />} onClick={novoAtendimento}>
                                Nova Consulta
                            </Button>
                        </div>
                        <TextField
                            color="primary"
                            size="small"
                            id="outlined-basic"
                            label="Buscar Consulta"
                            variant="outlined"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: <SearchIcon color="disabled" />,
                            }}
                        />

                        <CotaninerListaGravacoes>
                            {atendimentosFiltrados.map((atendimento) => (
                                <BootstrapTooltip
                                    placement="right"
                                    title={
                                        <React.Fragment>
                                            <Typography color="inherit" variant="caption">
                                                {atendimento.resumoGerado
                                                    ? 'Resumo gerado'
                                                    : atendimento.audiosCapturados
                                                    ? 'Audio capturado, porem o resumo ainda não foi gerado'
                                                    : 'Sem audio capturado'}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    key={atendimento.id}
                                >
                                    <ItemGravacao
                                        onClick={() => selecionaItem(atendimento)}
                                        selected={param?.id == atendimento.id}
                                        selectedcolor={theme.palette.secondary.main}
                                        unselectdcolor={theme.palette.secondary.main}
                                    >
                                        {atendimento.resumoGerado ? (
                                            <DoneAllIcon />
                                        ) : atendimento.audiosCapturados ? (
                                            <DoneIcon />
                                        ) : (
                                            <HourglassEmptyIcon />
                                        )}

                                        <Box display={'flex'} flexDirection={'column'} flex={1}>
                                            <Typography variant="body2" fontWeight={600}>
                                                {atendimento.nome}
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={'11px'}>
                                                Criado{' '}
                                                {new Date(atendimento.dataCriacao).toLocaleDateString() ===
                                                new Date().toLocaleDateString()
                                                    ? dayjs(atendimento.dataCriacao).fromNow()
                                                    : new Date(atendimento.dataCriacao).toLocaleDateString()}
                                            </Typography>
                                        </Box>

                                        {audiosNaoEnviados?.filter((i) => i.atendimentoId == atendimento.id).length != 0 && (
                                            <Tooltip title="Existem audios que não foram enviados ao servidor referente a este registro.">
                                                <IconButton>
                                                    <CloudOffIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </ItemGravacao>
                                </BootstrapTooltip>
                            ))}
                        </CotaninerListaGravacoes>
                        {!usuario?.pacote && !usuario?.corporativo && (
                            <Button
                                onClick={() => setOpenFormAssinaServico(true)}
                                variant="contained"
                                color="success"
                                size="small"
                                sx={{ fontWeight: 600 }}
                                fullWidth
                            >
                                Quero assinar o R1
                            </Button>
                        )}
                        <Box
                            display={isSmallScreen ? 'none' : 'flex'}
                            flexDirection={'column'}
                            border={`1px solid ${theme.palette.primary.main + '20'}`}
                            padding={'10px'}
                            borderRadius={'8px'}
                            gap={'10px'}
                            borderColor={theme.palette.primary.main + '20'}
                            title={'Clique para adquirir mais saldo'}
                            cursor={'pointer'}
                        >
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', alignItems: 'center' }}>
                                <Box>
                                    <Avatar sx={{ bgcolor: deepOrange[500], width: 30, height: 30 }}>
                                        {usuario?.name?.charAt(0)}
                                    </Avatar>
                                </Box>
                                <Box>
                                    <Typography variant="body1">{usuario?.name}</Typography>
                                    <Saldo
                                        onClick={() => setOpenFormAssinaServico(true)}
                                        title={`Seu saldo é ${
                                            usuario?.corporativo?.saldo || usuario?.saldo
                                        } e você pode comprar mais saldo clicando aqui!`}
                                        color={usuario?.corporativo?.saldo >= 5 || usuario?.saldo >= 5 ? 'green' : 'red'}
                                    >
                                        Saldo:{' '}
                                        {usuario?.corporativo?.saldo
                                            ? `${usuario?.corporativo?.saldo} Corporativo`
                                            : `${usuario?.saldo} consultas`}
                                    </Saldo>
                                </Box>
                            </div>
                            {usuario?.urlStoneMange && (
                                <Button LinkComponent={'a'} variant="contained" fullWidth>
                                    <a
                                        href={usuario.urlStoneMange}
                                        target="_blank"
                                        style={{ color: '#fff', textDecoration: 'none' }}
                                    >
                                        Gerenciar assinatura
                                    </a>
                                </Button>
                            )}
                            <Button
                                sx={{ color: '#161616', background: '#f4f4f4', ':hover': { background: '#f4f4f4' }, mt: 1 }}
                                variant="contained"
                                size="small"
                                fullWidth
                                onClick={logout}
                            >
                                Sair
                            </Button>
                        </Box>
                    </Itens>
                </Container>
            </Drawer>
            {isSmallScreen ? (
                <MenuTopMobile
                    setOpenFormAssinaServico={setOpenFormAssinaServico}
                    setOpenDrawer={setOpenDrawer}
                    logout={logout}
                />
            ) : (
                ''
            )}
            <Dialog
                open={openFormAssinaServico}
                onClose={() => setOpenFormAssinaServico(false)}
                PaperProps={{ sx: { maxWidth: 'unset' } }}
            >
                <DialogTitle sx={{ position: 'sticky', top: 0 }} textAlign={'center'}>
                    Assinatura
                    <CloseIcon onClick={() => setOpenFormAssinaServico(false)} />
                </DialogTitle>
                <DialogContent>
                    {usuario?.urlStoneMange ? (
                        <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
                            <Button LinkComponent={'a'} variant="contained" fullWidth>
                                <a href={usuario.urlStoneMange} target="_blank" style={{ color: '#fff', textDecoration: 'none' }}>
                                    Gerenciar assinatura
                                </a>
                            </Button>
                            <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                                Alterar dados de pagamento ou cancelar assinatura
                            </Typography>
                        </Box>
                    ) : (
                        <FormPagamento
                            usuario={usuario}
                            close={() => {
                                setOpenFormAssinaServico(false);
                                carregaUsuario();
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

export default MenuLateral;

function converterSegundosParaMinutos(segundos) {
    if (!segundos) return '0 min e 0 seg';
    const minutos = segundos / 60;
    const segundosRestantes = segundos % 60;
    return `${minutos.toFixed(0)} min e ${segundosRestantes.toFixed(0)} seg`;
}
