import styled, { css } from 'styled-components';

export const Container = styled.div`
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    height: 100%;
    background-color: #f4f4f4;
`;

export const Itens = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    margin: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #0000002e;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    overflow: hidden;
`;

export const CotaninerListaGravacoes = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    padding: 5px;

    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1976d220;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #1976d250;
    }
`;

export const ItemGravacao = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    /* border: 1px solid ${(props) => props.selectedColor}; */
    border: 1px solid ${(props) => props.selectedcolor + '00'};
    cursor: pointer;
    background-color: ${(props) => (props.selected ? props.selectedcolor : props.unselectdcolor + '20')};

    color: ${(props) => (props.selected ? '#fff' : props.unselectdcolor)};
    transition-duration: 0.3s;
    box-shadow: ${(props) => `0px 0px 5px 0px ${props.selectedcolor}${props.selected ? '' : '20'}`};
    user-select: none;
    &:hover {
        /* transform: scale(0.955); */
        color: ${(props) => (props.selected ? '#fff' : '#70157f')};
        border: 1px solid ${(props) => (props.selected ? '#ffffff00' : '#70157f')};
    }
`;

export const Saldo = styled.h2`
    margin: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.57;
    letter-spacing: 0.00714em;
    color: ${(props) => props.color || '#000'};
    font-weight: 600;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
