import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    > .containerContator > span {
        font-size: 0.7rem;
        font-weight: 600;
        margin-left: 5px;
        > b {
            color: #ff4d4d;
        }
    }

    @media screen and (max-width: 800px) {
        > .containerContator {
            display: none;
        }
    }
`;
export const ResumoStreamDiv = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 1200;
    background: #000000d9;
    backdrop-filter: blur(4px);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 500;

    > article {
        width: 100%;
        max-width: 800px;

        height: 100vh;
        display: flex;
        flex-direction: column;
        color: #fff;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #262626;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #1976d250;
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #1976d250;
        }
    }
`;

export const Gravador = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh / 2 - 120px);
    flex: 1;

    padding: 20px 10px;
    background: ${(props) => props.primarycolor + 20};
    z-index: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px ${(props) => props.primarycolor + 20};
    margin: 0px 5px;
    /* max-height: calc(100vh / 2 - 100px);
    min-height: 300px; */
    > div {
        width: 100%;
    }

    > .containerCanvas {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        filter: blur(30px) brightness(1.25);

        > canvas {
            width: 100%;
            height: 100%;
        }
    }

    .containerBtnGravar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1;
    }

    > .containerBtnGravar > .btnGravar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #ff4d4d;
        box-shadow: 0px 0px 20px 3px rgba(255, 77, 77, 1);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border: none;
    }
    > .containerBtnGravar > .btnConfiguracoes {
        position: absolute;
        top: 54px;
        right: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: ${(props) => props.primarycolor};
        /* box-shadow: 0px 0px 20px 3px ${(props) => props.primarycolor + 'c2'}; */
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border: none;
        transform: translateX(75px) translateY(-35px);
    }

    > .containerBtnGravar > .btnGravar:hover {
        background-color: #a82929;
        transform: scale(0.95);
    }
    > .containerBtnGravar > .btnConfiguracoes:hover {
        transform: translateX(75px) translateY(-35px) scale(0.95);
    }
`;

export const ButtonResumo = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${(props) => props.color};
    color: #fff;
    padding: 10px 20px;
    border: none;
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 0px 12px 2px ${(props) => props.color};

    &:hover {
        transform: scale(0.95);
        opacity: 0.95;
    }
`;

export const Separador = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 5px 0px;
    > div {
        flex: 1;
        border-top: 1px solid #f1f1f1;
    }
    > h1 {
        font-size: 1rem;
        font-weight: 600;
        color: #555;
    }
`;

export const Resumo = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    flex: 1;
    height: 100%;
    overflow: hidden;

    > .headerResumo {
        display: flex;
        flex-direction: column;

        padding: 5px 10px;
        /* background-color: #f1f1f1; */
        border-bottom: 1px solid #f1f1f1;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        > div > h1 {
            font-size: 0.7rem;
            font-weight: 600;
            margin: 0px;
        }

        > div > h6 {
            font-size: 0.5rem;
            font-weight: 400;
            margin: 0px;
        }
    }

    > .bodyResumo {
        border: 1px solid #f1f1f1;
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 10px;
        flex: 1;
        overflow: hidden;
        height: 100%;
        /* padding-bottom: 50px; */
    }
`;

export const DivAvaliacaoTexto = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
`;

export const LoadingBall = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.5) inset, 0 5px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    margin-bottom: 20px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 40%;
        top: -40%;
        background-color: #debffa;
        animation: wave 5s cubic-bezier(0, 1.74, 1, 0) infinite;
    }

    &:before {
        border-radius: 30%;
        background: rgba(255, 255, 255, 0.4);
        animation: wave 3s linear infinite;
    }
`;
