export default async function obterDuracaoAudio(arquivo) {    
    let url = '';
    if (arquivo instanceof Blob) {
        url = URL.createObjectURL(arquivo);
    } else if (typeof arquivo === 'string') {
        url = arquivo;
    }
    if(document.visibilityState == 'hidden'){
        return 71
    }
    return new Promise((resolve, reject) => {
        const audio = new Audio(url);      
        audio.addEventListener('loadedmetadata', () => {
            try{
                resolve(parseFloat(audio.duration.toFixed(2)));
            }catch(error){
                console.error(error);
                resolve(71)
            }
        });
    });
}
