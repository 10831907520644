import React from 'react';

import { ContainerSucesso } from './Cadastro.styles';
import { useNavigate } from 'react-router-dom';
import { Box, Button, useTheme, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
function SucessoCadastro() {
    const location = useLocation();
    const navegate = useNavigate();
    const theme = useTheme();
    React.useEffect(() => {
        console.log('Ativando Pixel analytics');
        ReactPixel.pageView();

        console.log('Ativando Google analytics.');
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return (
        <ContainerSucesso>
            <h1 style={{ color: theme.palette.primary.main, textTransform: 'uppercase' }}>SUA CONTA FOI CRIADA COM SUCESSO!</h1>
            <p>
                Você pode utilizar em{' '}
                <b style={{ color: theme.palette.primary.main, fontSize: '18px' }}>
                    10 CONSULTAS GRATUITAMENTE NOS PRÓXIMOS 7 DIAS
                </b>{' '}
                para experimentar uma nova forma de atender seus pacientes. Se tiver alguma dúvida, entre em contato conosco
                através do chat clicando no icone azul flutuante à direita.
            </p>

            <Box marginTop={'20px'} display={'flex'} flexDirection={'column'} gap={'5px'}>
                <Button
                    variant="contained"
                    type="submit"
                    onClick={() => navegate('/main', { replace: true, state: { solicitaEspecialidade: true } })}
                >
                    ACESSAR R1 AGORA
                </Button>
            </Box>
        </ContainerSucesso>
    );
}

export default SucessoCadastro;
