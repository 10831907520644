import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
    Container,
    Gravador,
    Resumo,
    Separador,
    ButtonResumo,
    DivAvaliacaoTexto,
    LoadingBall,
    ResumoStreamDiv,
} from './LinhaDoTempo.styles';
import { getAtendimentoById } from '../../../services/atendimento';
import {
    avaliaResumo,
    createResumo,
    getChatCompletionStream,
    getChatCompletionStreamV2,
    getChatCompletionStreamV3,
    geraResumoStream,
    getTiposResumos,
    tiposDeRegistrosIAInteligente,
    postGeraResumoJson,
} from '../../../services/resumo';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Player } from '@lottiefiles/react-lottie-player';
import { useParams } from 'react-router-dom';
import MicIcon from '@mui/icons-material/Mic';
import ScienceIcon from '@mui/icons-material/Science';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import PauseIcon from '@mui/icons-material/Pause';
import SettingsIcon from '@mui/icons-material/Settings';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { saveAudio, enviaTesteAudio } from '../../../services/audio';
import * as dayjs from 'dayjs';
import animation_uploadJson from '../../../assets/animation_upload.json';
import animation_resumoJson from '../../../assets/animation_resumo.json';
import 'dayjs/locale/pt-br';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fade,
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material';
import MicNoneIcon from '@mui/icons-material/MicNone';
import useMic, { ErrorMicrofone } from '../../../services/useMic';
import { AudioR1 } from '../../../config/IndexDBConfig';
import obterDuracaoAudio from '../../../helpers/getDuracaoAudio';
import { toast } from 'react-toastify';
// import useAudioStorage from '../../../services/useAudioStorage';
import { db } from '../../../config/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { useMain } from '../../../contexts/MainContext';
import { ContentState, EditorState, convertFromRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import WarningImg from '../../../assets/main/atencao.png';
import { saveHistoricoResumo } from '../../../services/historicoResumo';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { red, green } from '@mui/material/colors';
import AudioQuality from '../../../elementos/audioQuality/AudioQuality';
import { set } from 'react-ga';
import { func } from 'prop-types';
import BlocoResumo from '../Components/BlocoResumo/BlocoResumo';
dayjs.locale('pt-br');
dayjs.extend(relativeTime);

function LinhaDoTempo({ setTipoAtendimento, atendimentoParam, tiposResumo, setProtocolo, popupProtocoloIsOpen }) {
    const corGerandoBlob = '#030637';
    const corEnvioServidor = '#8EAC50';
    const corSalvaIndexedDB = '#D3D04F';
    const editorRef = React.useRef(null);
    const editorRef2 = React.useRef(null);
    const { usuario, carregaUsuario, setGlobalIsRecording, globalIsRecording } = useMain();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const param = useParams();
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loadingResumo, setLoadingResumo] = useState(false);
    const [atendimento, setAtendimento] = useState({ audios: [], resumos: [] });
    const [dispositivosMic, setDispositivosMic] = useState([]);
    const [dispositivoSelecionado, setDispositivoSelecionado] = useState(null);
    const [tipoResumo, setTipoResumo] = React.useState({});

    const [openDialogTipoResumo, setOpenDialogTipoResumo] = React.useState(false);
    const [openDialogSelectMic, setOpenDialogSelectMic] = React.useState(false);
    const [openDialogTestMic, setOpenDialogTestMic] = React.useState(false);
    const [error, setError] = useState(null);
    const [audiosNaoEnviadosMac, setAudiosNaoEnviadosMac] = useState([]);
    const [popupConfirma, setPopupConfirma] = useState(false);
    const [openDialogConfirmaTipoConsulta, setOpenDialogConfirmaTipoConsulta] = useState(false);
    const [openDialogAvisoIa, setOpenDialogAvisoIa] = useState(false);
    const [recebendoResumoStream, setRecebendoResumoStream] = useState(false);
    const resumoStreamDivRef = useRef(null);
    const [textoMontado, setTextoMontato] = useState('');
    const [modalProtocoloIsOpen, setModalProtocoloIsOpen] = useState(false);
    const [btnCloseRecebendoResumoStream, setBtnCloseRecebendoResumoStream] = useState(false);
    const onlyTiposResumos = useMemo(() => tiposResumo.filter((i) => !i.protocolo), [tiposResumo]);
    const onlytipoProtocolos = useMemo(() => tiposResumo.filter((i) => i.protocolo), [tiposResumo]);

    const greenColor = green[500];
    const redColor = red[500];
    const audiosNaoEnviados = useLiveQuery(async () => {
        return await db.audios.where({ atendimentoId: param.id, status: 'local' }).toArray();
    }, [param]);
    const audiosBarRef = useRef(null);
    const { isRecording, startRecording, stopRecording, solicitaPermissaoMicrofone, somBaixo, analyser } = useMic();

    useEffect(() => {
        if (audiosNaoEnviados) console.log('audiosNaoEnviados', audiosNaoEnviados);
    }, [audiosNaoEnviados]);

    useEffect(() => {
        setGlobalIsRecording(isRecording);
    }, [isRecording]);

    useEffect(() => {
        console.log(tipoResumo);
    }, [tipoResumo]);

    const handleClickTipoResumo = (value) => {
        console.log(value);
        setTipoAtendimento({ ...atendimentoParam, tipoAtendimento: value });
        setOpenDialogTipoResumo(false);
        setTipoResumo(value);
        // if (atendimentoParam?.audios?.length) {
        //     gerarResumo(value.value);
        // }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const atualizaInfoAudios = (arrayAudios, audioInfo) => {
        try {
            const arrayAudiosCopy = arrayAudios;
            const findIndexAudio = arrayAudiosCopy.findIndex((audio) => audio._name == audioInfo._name);
            if (findIndexAudio != -1) {
                arrayAudiosCopy[findIndexAudio] = audioInfo;
                return arrayAudiosCopy;
            } else {
                throw 'Audio informado, não encontrado.';
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível atualizar informações do audio.');
            return arrayAudios;
        }
    };

    async function avaliarTexto(avaliacao, idResumo) {
        try {
            await avaliaResumo(idResumo, avaliacao);
            const novoAtendimento = { ...atendimento };
            novoAtendimento.resumos[0].satisfaito = avaliacao;
            setAtendimento(novoAtendimento);
            if (avaliacao) {
                toast.success('👍 Agradecemos pela avaliação.');
            } else {
                toast.success('☹️ Agradecemos pela avaliação, visando melhorar nosso serviço vamos analisar o seu caso.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Erro ao tentar avaliar resumo.');
        }
    }

    const tempoTotalAudios = useMemo(() => {
        const tempoTotalAudios = atendimento.audios.reduce((acc, cur) => acc + cur.duracao, 0);
        return tempoTotalAudios;
    }, [atendimento.audios]);

    const resumosComAudios = useMemo(() => {
        const resumosComAudios = atendimento.resumos.map((resumo) => {
            const audioDoResumo = atendimento.audios
                .filter((audio) => new Date(audio.dataCriacao) <= new Date(resumo.dataCriacao))
                .map(
                    (audio) =>
                        new AudioR1(audio.BlobUrl, audio.AtendimentoId, 'server', audio.DataCriacao, new Blob(), audio.duracao),
                );
            return {
                ...resumo,
                texto: resumo.texto.replace(/\\n/g, '').replace('```html', '').replace('```', ''),
                minutosTotalAudios: audioDoResumo.reduce((acc, cur) => acc + cur.duracao, 0),
                audios: audioDoResumo,
            };
        });
        // console.log('resumosComAudios', resumosComAudios);
        return resumosComAudios.sort((a, b) => new Date(b.data) - new Date(a.data));
    }, [atendimento]);

    useEffect(() => {
        init(param.id, true);
    }, [param.id]);

    useEffect(() => {
        console.log(audiosNaoEnviadosMac);
    }, [audiosNaoEnviadosMac]);

    const callbackAudio = useCallback(
        async (blob) => {
            console.log('%c 🔉 Criando blob e armazenando em memória.', `color:${corGerandoBlob}`);
            const nameBlob = URL.createObjectURL(blob);
            console.log('%c 🔉 Blob criado e armazenado em memória', `color:${corGerandoBlob}`);
            console.log("%c 🔉 Retirando o 'domínio' do nome do blob", `color:${corGerandoBlob}`);
            const nomeSemDominio = nameBlob.replace(`blob:${window.location.origin}/`, '');
            console.log(nomeSemDominio);
            console.log('%c 🔉 Dominio retirado do nome do blob', `color:${corGerandoBlob}`);
            console.log('%c 🔉Pegando o tempo de duração do blob', `color:${corGerandoBlob}`);
            const duracao = await obterDuracaoAudio(blob);
            console.log(duracao);
            console.log('%c 🔉 Tempo de duracao do blob foi pego', `color:${corGerandoBlob}`);
            console.log('%c 🔉 Gerando novo áudio e salvando em memória', `color:${corGerandoBlob}`);
            const novoAudio = new AudioR1(nomeSemDominio + '.mp3', param.id, 'local', new Date(), blob, duracao);
            console.log(novoAudio);
            console.log('%c 🔉 Novo áudio gerado', `color:${corGerandoBlob}`);
            console.log('%c ☁️ Iniciando processo de salvar o áudio', `color:${corEnvioServidor}`);
            //ARMAZENANDO AUDIOS GERADOS NA VARIÁVEL audiosNaoEnviadosMac

            setAudiosNaoEnviadosMac((oldValue) => {
                oldValue.push(novoAudio);
                return oldValue;
            });

            try {
                console.log(
                    `%c ☁️ [Callback salva audio]  Ininciando a gravação AudioR1, gravando no [servidor].`,
                    `color:${corEnvioServidor}`,
                );

                const resultSaveAudio = await saveAudio(
                    novoAudio.blob,
                    novoAudio.name,
                    novoAudio.atendimentoId,
                    dayjs(novoAudio.date).format('YYYY-MM-DD HH:mm'),
                );

                const objAudio = {
                    tipo: 'audio',
                    blob: novoAudio.blob,
                    name: novoAudio.name,
                    atendimentoId: novoAudio.atendimentoId,
                    final: false,
                };
                window.parent.postMessage(objAudio, '*');
                if (resultSaveAudio.status == 200) {
                    let novoArray = atualizaInfoAudios(audiosNaoEnviadosMac, { ...novoAudio, _status: 'server' });
                    setAudiosNaoEnviadosMac(novoArray);
                }
                console.log(resultSaveAudio);
                console.log('%c ☁️ Resultado do servidor salvando áudio', `color:${corEnvioServidor}`);
                try {
                    console.log(
                        `%c 🖥️ [Callback salva audio] Ininciando a gravação AudioR1, gravando no [indexDB].`,
                        `color:${corSalvaIndexedDB}`,
                    );
                    const id = await db.audios.add({
                        name: novoAudio.name,
                        atendimentoId: novoAudio.atendimentoId,
                        status: resultSaveAudio.status == 200 ? 'server' : 'local',
                        date: novoAudio.date,
                        blob: novoAudio.blob,
                        duracao: novoAudio.duracao,
                    });
                    console.log(
                        `%c 🖥️ [Callback salva audio] AudioR1 [${id}] gravado no [indexDB].`,
                        `color:${corSalvaIndexedDB}`,
                    );
                } catch (error) {
                    console.log(
                        `%c 🖥️ [Callback salva audio] Erro ao gravar AudioR1 no [indexDB].`,
                        `color:${corSalvaIndexedDB}`,
                    );
                    console.error(error);
                }
                // await carregaUsuario();
                // console.log(`%c ☁️ [Callback salva audio] array de audios.`,`color:${corEnvioServidor}`,audiosNaoEnviadosMac);
                console.log(
                    `%c ☁️ [Callback salva audio] finalizado a gravação AudioR1 no [servidor].`,
                    `color:${corEnvioServidor}`,
                );
            } catch (error) {
                console.log(`%c ☁️ [Callback salva auido] Erro ao gravar AudioR1 no [servidor].`, error);
                toast.error('Erro ao salvar no servidor audio.');
                if (error?.response?.data?.errors.length) {
                    error?.response?.data?.errors.forEach.call(error.response.data.errors, (erro) => {
                        toast.error(erro);
                    });
                    return;
                }
            } finally {
                init(param.id);
            }
        },
        [param],
    );

    const carregaTipoConsulta = (tipoConsulta, arrayTipoConsulta) => {
        if (tipoConsulta) {
            let findTipoConsulta = arrayTipoConsulta.find((tipoConsultaEl) => tipoConsultaEl.value == tipoConsulta);
            return findTipoConsulta;
        }
    };

    async function init(idAtendimento, isInit = false) {
        try {
            setLoading(true);
            const [dispositivos, atendimento] = await Promise.all([
                solicitaPermissaoMicrofone(),
                getAtendimentoById(idAtendimento),
            ]);
            const tipoResumoAtual = tipoResumo?.name ? tipoResumo : onlyTiposResumos[0];

            setTipoResumo(tipoResumoAtual);
            setDispositivosMic(dispositivos);
            setDispositivoSelecionado(dispositivos[0]);
            setAtendimento(atendimento);

            if (atendimento.resumos.length) {
                setTipoAtendimento({
                    ...atendimento,
                    tipoAtendimento: carregaTipoConsulta(atendimento.resumos[0].tipo, onlyTiposResumos),
                });
            } else {
                setTipoAtendimento({ ...atendimento, tipoAtendimento: tipoResumoAtual });
            }
            // if (isInit && !window.location.hostname.includes('localhost')) {
            if (isInit) {
                if (!atendimento.resumos.length || !atendimento.audios.length) setOpenDialogTipoResumo(true);
            }
            if (!Notification.permission === 'granted' && Notification.permission === 'denied') {
                askNotificationPermission();
            }
        } catch (error) {
            if (error instanceof ErrorMicrofone) {
                toast.error(error.message);
                setError(error.message);
            }
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const gravarOuPausar = useCallback(async () => {
        try {
            if (isRecording) {
                setLoadingUpload(true);
                const blob = await stopRecording(audiosBarRef.current);
                await callbackAudio(blob);
                return;
            }

            if (!dispositivoSelecionado) return toast.error('Nenhuma dispositivo de microfone selecionado!');
            await startRecording(dispositivoSelecionado.deviceId, callbackAudio, audiosBarRef.current);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingUpload(false);
            init(param.id, false);
        }
    }, [isRecording, startRecording, stopRecording, param, dispositivoSelecionado, tipoResumo]);

    function processarStreamString(streamString) {
        let arrayParcial = '';

        return function (novaString) {
            // Concatena a nova string com a parte anterior, se houver
            const stringCompleta = arrayParcial + novaString;

            try {
                // Tenta analisar a string como um JSON
                const objeto = JSON.parse(stringCompleta);

                // Verifica se o objeto é um array
                if (Array.isArray(objeto)) {
                    // Se for um array, manipule-o aqui conforme necessário
                    console.log('Array recebido:', objeto);
                } else {
                    // Se não for um array, faça algo com o objeto aqui
                    console.log('Objeto recebido:', objeto);
                }

                // Limpa a parte do array parcial, pois já foi processada
                arrayParcial = '';
            } catch (error) {
                // Se ocorrer um erro ao analisar a string como JSON, pode ser que ainda não tenha recebido a string completa
                // Armazena a parte da string para processar posteriormente
                const ultimoBracketIndex = stringCompleta.lastIndexOf(']');
                if (ultimoBracketIndex !== -1) {
                    arrayParcial = stringCompleta.substring(ultimoBracketIndex + 1);
                } else {
                    // Se não houver um fechamento de array, mantenha a parte da string para o próximo fluxo
                    arrayParcial = stringCompleta;
                }
            }
        };
    }

    const gerarResumo = useCallback(
        async (tipo) => {
            try {
                setLoadingResumo(true);
                setTextoMontato('');
                setRecebendoResumoStream(true);
                const idAtendimento = param.id;
                // const processarStream = processarStreamString();

                geraResumoStream(
                    { idAtendimento: idAtendimento, tipo: tipo },
                    (error) => {
                        toast.error(error, {
                            autoClose: 5000,
                        });
                        console.error('EventSource failed:', error);
                        setLoadingResumo(false);
                        setBtnCloseRecebendoResumoStream(true);
                        setTimeout(() => {
                            setRecebendoResumoStream(false);
                        }, 2000);
                    },
                    (isFinish, data) => {
                        if (isFinish) {
                            toast.success('Resumo gerado com sucesso!');
                            setBtnCloseRecebendoResumoStream(true);
                            setLoadingResumo(false);
                            setOpenDialogAvisoIa(true);
                            setTimeout(() => {
                                init(idAtendimento);
                                geraResumoJson(idAtendimento);
                            }, 1500);
                            return;
                        }

                        try {
                            setRecebendoResumoStream(true);
                            setTextoMontato((old) => old + data);
                        } catch (error) {
                            setLoadingResumo(false);
                            console.log(`Erro ao tentar montar texto de: ${data}`);
                            console.error(error);
                        }
                    },
                );
                // setLoadingResumo(true);
            } catch (error) {
                if (error?.response?.status == 400 && error?.response?.data) {
                    toast.error(error.response.data);
                } else {
                    toast.error('Erro ao gerar resumo!');
                }
                console.error(error);
            } finally {
                // init(param.id);
            }
        },
        [isRecording, stopRecording, param, tipoResumo],
    );

    async function geraResumoJson(idAtendimento) {
        try {
            const result = await postGeraResumoJson(idAtendimento);
            init(param.id);
            console.log('Result gerar resumo json', result);
        } catch (error) {
            toast.error('Erro ao gerar resumo json');
            console.error(error);
        }
    }

    const reenviarAudioLocal = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                if (audiosNaoEnviados)
                    await Promise.all(
                        audiosNaoEnviados?.map(async (audio) => {
                            try {
                                await saveAudio(
                                    audio.blob,
                                    audio.name,
                                    audio.atendimentoId,
                                    dayjs(audio.date).format('YYYY-MM-DD HH:mm'),
                                );
                                await atualizaByNameBlobInIndexDB(audio.name, { status: 'server' });
                            } catch (error) {
                                console.error(error);
                                console.log('Erro ao reenviar audio', audio);
                            }
                        }),
                    );
                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            } finally {
                setLoading(false);
            }
        });
    }, [audiosNaoEnviados]);

    async function confirmaTipoConsulta() {
        try {
            setLoadingUpload(true);
            if (isRecording) {
                const blob = await stopRecording(audiosBarRef.current);
                callbackAudio(blob);
            }
            if (audiosNaoEnviados.length > 0) {
                await reenviarAudioLocal();
            }
            if (!tipoResumo?.id) return escolheResumo(true);
            setOpenDialogConfirmaTipoConsulta(true);
        } catch (error) {
            console.error('error');
        } finally {
            setLoadingUpload(false);
        }
    }

    async function escolheResumo(isConfirma) {
        try {
            setPopupConfirma(isConfirma);
            setLoadingUpload(true);
            if (isRecording) {
                const blob = await stopRecording(audiosBarRef.current);
                callbackAudio(blob);
            }
            if (audiosNaoEnviados.length > 0) {
                await reenviarAudioLocal();
            }
            setOpenDialogTipoResumo(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingUpload(false);
        }
    }

    async function copiarTexto(idAtendimento) {
        setTimeout(async () => {
            try {
                let texto = '';
                const atendimento = await getAtendimentoById(idAtendimento);
                const resumo = atendimento.resumos[0];

                const htmlToText = (html) => {
                    const div = document.createElement('div');
                    div.innerHTML = html;
                    return div.textContent || div.innerText || '';
                };
                if (resumo.json) {
                    try {
                        const objResumo = JSON.parse(resumo.json);
                        const blocos = objResumo.informacoes;
                        blocos.forEach((bloco) => {
                            texto += `${bloco.titulo}\n\n ${bloco.texto}\n\n\n`;
                        });
                    } catch (error) {
                        try {
                            texto = htmlToText(resumo.texto);
                        } catch (error) {
                            console.log('Json não encontrado ou com erro', error);
                        }
                    }
                } else {
                    try {
                        if (!resumo.texto) throw 'Texto não encontrado';
                        texto = htmlToText(resumo.texto);
                        toast.success('Texto copiado com sucesso!');
                    } catch (error) {
                        console.log('Texto não encontrado', error);
                    }
                }
                if (!texto) throw 'Texto não encontrado';
                navigator.clipboard.writeText(texto);
                toast.success('Texto copiado com sucesso!');
            } catch (error) {
                console.error(error);
                toast.error('Erro ao copiar texto!');
            }
        }, 1000);
    }

    function askNotificationPermission() {
        // Let's check if the browser supports notifications
        toast.info('Aceite as notificações para receber alertas caso o áudio esteja muito baixo.');
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.');
            toast.warning('Esse navegador não suporta notificações.');
        } else {
            Notification.requestPermission().then((permission) => {
                console.log('Notification permission:', permission);
                toast.success('Permissão para notificações concedida.');
            });
        }
    }

    async function closeResumoStreamDiv() {
        setRecebendoResumoStream(false);
        // const resumo = await createResumo(param.id, tipoResumo?.value);
        const objectResumo = {
            tipo: 'resumo',
            idAtendimento: param.id,
            tipoResumo: tipoResumo?.value,
            texto: textoMontado,
            final: true,
        };
        window.parent.postMessage(objectResumo, '*');
        setBtnCloseRecebendoResumoStream(false);
    }

    if (error)
        return (
            <Box flex={1} display="flex" justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                <h1>Ocorreu um erro!</h1>
                <h2 style={{ textAlign: 'center', color: '#c55f5f' }}>{error}</h2>
            </Box>
        );

    return (
        <Container>
            <div className="containerContator">
                {resumosComAudios.length > 0 ? (
                    <span>
                        Tempo total de audio: <b>{converterSegundosParaMinutos(tempoTotalAudios)}</b>
                    </span>
                ) : (
                    <span>
                        Minutos de Audios já Processados: <b>{converterSegundosParaMinutos(tempoTotalAudios)}</b>
                    </span>
                )}
            </div>
            {resumosComAudios.length == 0 && (
                <Gravador primarycolor={theme.palette.primary.main} style={{ overflow: isRecording ? 'hidden' : 'initial' }}>
                    <div className="containerCanvas">
                        <canvas ref={audiosBarRef} style={{ display: isRecording ? 'flex' : 'none' }}></canvas>
                    </div>

                    <div className="containerBtnGravar">
                        {somBaixo && (
                            <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                                <Chip icon={<MicNoneIcon />} label={somBaixo} color="error" />
                            </Box>
                        )}
                        {isRecording ? (
                            <Typography variant="h5" color={theme.palette.error.dark}>
                                Ouvindo Atendimento...
                            </Typography>
                        ) : (
                            <Typography variant="h5">
                                {tempoTotalAudios == 0
                                    ? 'Clique para começar a ouvir atendimento'
                                    : 'Clique para continuar ouvindo o atendimento'}
                            </Typography>
                        )}
                        <button
                            title={isRecording ? 'Pausar' : 'Ouvir Atendimento'}
                            className={`btnGravar ${isRecording ? 'pulse-red' : ''}`}
                            onClick={gravarOuPausar}
                        >
                            {isRecording ? (
                                <PauseIcon sx={{ color: '#fff' }} fontSize={'large'} />
                            ) : (
                                <MicIcon sx={{ color: '#fff' }} fontSize={'large'} />
                            )}
                        </button>
                        {!isRecording && (
                            <button title="Configurações" className={`btnConfiguracoes`} onClick={handleClick}>
                                {!open ? (
                                    <SettingsIcon
                                        sx={{ color: '#fff' }}
                                        fontSize={'small'}
                                        primarycolor={theme.palette.primary.dark}
                                    />
                                ) : (
                                    <CloseIcon
                                        sx={{ color: '#fff' }}
                                        fontSize={'small'}
                                        primarycolor={theme.palette.primary.dark}
                                    />
                                )}
                            </button>
                        )}
                    </div>
                    {/* {isRecording && <AudioQuality analyser={analyser} />} */}
                    {!isRecording && (
                        <div>
                            {audiosNaoEnviados?.length ? (
                                <ButtonResumo color={theme.palette.primary.dark} onClick={confirmaTipoConsulta}>
                                    {`Pausar e Gerar Prontuário`}
                                </ButtonResumo>
                            ) : (
                                <ButtonResumo color={theme.palette.primary.dark} onClick={confirmaTipoConsulta}>
                                    {`${isRecording ? 'Pausar e ' : ''} Gerar Prontuário`}
                                </ButtonResumo>
                            )}
                        </div>
                    )}
                </Gravador>
            )}
            {!isRecording &&
                resumosComAudios.length > 0 &&
                [resumosComAudios[0]].map((resumo) => (
                    <Resumo key={`resumoId-${resumo.id}`}>
                        <div className="headerResumo">
                            <Box display={'flex'} justifyContent={'space-between'}>
                                {/* <h1>{`Criado ${dayjs(resumo.dataCriacao).fromNow()}`}</h1> */}
                                <DivAvaliacaoTexto>
                                    <sub style={{ marginRight: '8px', fontSize: '10px' }}>O texto gerado pela IA foi útil ?</sub>
                                    {resumo.satisfaito ? (
                                        <ThumbUpAltIcon
                                            sx={{ fontSize: 14, color: greenColor, cursor: 'pointer', marginRight: '10px' }}
                                            onClick={() => avaliarTexto(true, resumo.id)}
                                        />
                                    ) : (
                                        <ThumbUpAltOutlinedIcon
                                            sx={{ fontSize: 14, color: greenColor, cursor: 'pointer', marginRight: '10px' }}
                                            onClick={() => avaliarTexto(true, resumo.id)}
                                        />
                                    )}
                                    {!resumo.satisfaito ? (
                                        <ThumbDownAltIcon
                                            sx={{ fontSize: 14, color: redColor, cursor: 'pointer', marginRight: '10px' }}
                                            onClick={() => avaliarTexto(false, resumo.id)}
                                        />
                                    ) : (
                                        <ThumbDownOffAltIcon
                                            sx={{ fontSize: 14, color: redColor, cursor: 'pointer', marginRight: '10px' }}
                                            onClick={() => avaliarTexto(false, resumo.id)}
                                        />
                                    )}
                                </DivAvaliacaoTexto>
                                <Box display={'flex'} gap={'5px'}>
                                    <Button
                                        sx={{ fontWeight: 600, fontSize: '10px' }}
                                        size="small"
                                        variant="outlined"
                                        startIcon={<ContentCopyIcon sx={{ fontSize: '10px' }} />}
                                        onClick={() => copiarTexto(atendimento.id)}
                                    >
                                        Copiar Texto
                                    </Button>
                                    {!popupProtocoloIsOpen && !!onlytipoProtocolos.length && (
                                        <Button
                                            sx={{ fontWeight: 600, fontSize: '10px' }}
                                            size="small"
                                            variant="contained"
                                            startIcon={<AutoAwesomeIcon sx={{ fontSize: '10px' }} />}
                                            onClick={() => setModalProtocoloIsOpen(true)}
                                        >
                                            Gerar Protocolo
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </div>

                        <div className="bodyResumo">
                            <BlocoResumo resumo={resumo} />
                            {/* <article className="articleResumo" dangerouslySetInnerHTML={{ __html: resumo.texto }}></article> */}
                        </div>
                    </Resumo>
                ))}
            <ResumoStreamDiv ref={resumoStreamDivRef} style={{ display: recebendoResumoStream ? 'flex' : 'none' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                    {btnCloseRecebendoResumoStream && (
                        <Button sx={{ color: '#fff', marginRight: '10px' }} variant="contained" onClick={closeResumoStreamDiv}>
                            Fechar
                        </Button>
                    )}
                </div>
                {loadingResumo && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: -1,
                            opacity: 0.3,
                            filter: 'blur(7px)',
                            width: '200px',
                            height: '200px',
                        }}
                    >
                        <LoadingBall color={theme.palette.primary.main}></LoadingBall>
                    </div>
                )}
                {!textoMontado && <h2 style={{ color: '#fff' }}>Aguarde, seu R1 esta analisando as informações...</h2>}
                <article dangerouslySetInnerHTML={{ __html: textoMontado }}></article>
            </ResumoStreamDiv>
            <Popper
                // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
                sx={{ zIndex: 1200 }}
                open={open}
                anchorEl={anchorEl}
                placement={'left-start'}
                transition
                modifiers={[
                    {
                        name: 'offset',
                        enabled: true,
                        options: {
                            offset: [0, 5],
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper disableGutters disablePadding>
                            <List dense>
                                <ListItem disableGutters disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            setOpenDialogSelectMic(true);
                                            setOpen(false);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <SettingsVoiceIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                color: 'primary',
                                                fontWeight: 'bold',
                                                variant: 'body2',
                                            }}
                                            secondaryTypographyProps={{
                                                color: 'text.secondary',
                                                fontWeight: 'medium',
                                                variant: 'body2',
                                                sx: { maxWidth: 200, fontSize: '0.8rem' },
                                            }}
                                            primary="Escolher Microfone"
                                            secondary="Escolher com dispositivo de microfone deseja utilizar."
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disableGutters>
                                    <ListItemButton
                                        onClick={() => {
                                            setOpenDialogTestMic(true);
                                            setOpen(false);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <ScienceIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                color: 'primary',
                                                fontWeight: 'bold',
                                                variant: 'body2',
                                            }}
                                            primary="Testar Microfone"
                                            secondaryTypographyProps={{
                                                color: 'text.secondary',
                                                fontWeight: 'medium',
                                                variant: 'body2',
                                                sx: { maxWidth: 200, fontSize: '0.8rem' },
                                            }}
                                            secondary="Testar Microfone"
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <ReponseTypeDialog
                popupConfirma={popupConfirma}
                tiposResumo={onlyTiposResumos}
                selectedValue={tipoResumo}
                open={openDialogTipoResumo}
                onClose={() => setOpenDialogTipoResumo(false)}
                onSelected={handleClickTipoResumo}
            />
            <DialogTipoProtocolos
                tiposProtocolos={onlytipoProtocolos}
                open={modalProtocoloIsOpen}
                onClose={() => setModalProtocoloIsOpen(false)}
                onSelected={setProtocolo}
            />
            <SelectMicDialog
                selectedValue={dispositivoSelecionado}
                values={dispositivosMic}
                open={openDialogSelectMic}
                onClose={() => setOpenDialogSelectMic(false)}
                onSelected={(value) => setDispositivoSelecionado(value)}
            />
            <TestMicDialog
                open={openDialogTestMic}
                onClose={() => setOpenDialogTestMic(false)}
                dispositivoSelecionado={dispositivoSelecionado}
                setError={setError}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loadingUpload}
                onClick={() => setLoadingUpload(false)}
            >
                <Typography variant="h5">Processando audio...</Typography>
                <Typography variant="subtitle1">Isso pode levar alguns segundos.</Typography>
                <Player
                    onEvent={(event) => {
                        //if (event === 'load') console.log('load'); // check event type and do something
                    }}
                    autoplay={true}
                    loop={true}
                    controls={true}
                    src={animation_uploadJson}
                    style={{ height: '300px', width: '300px' }}
                ></Player>
            </Backdrop>

            <Dialog open={openDialogConfirmaTipoConsulta} onClose={() => setOpenDialogConfirmaTipoConsulta(false)}>
                <DialogContent>
                    <Typography textAlign={'center'} variant="body2">
                        Confirmar tipo de consulta:
                    </Typography>
                    <Typography textAlign={'center'} variant="body1" fontWeight={'bold'}>
                        {tipoResumo?.name}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ flex: 1 }}
                        onClick={() => {
                            setOpenDialogConfirmaTipoConsulta(false);
                            escolheResumo(true);
                        }}
                    >
                        Trocar
                    </Button>
                    <Button
                        sx={{ flex: 1 }}
                        variant="contained"
                        onClick={() => {
                            setOpenDialogConfirmaTipoConsulta(false);
                            gerarResumo(tipoResumo.value);
                        }}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDialogAvisoIa} onClose={() => setOpenDialogAvisoIa(false)}>
                <DialogTitle alignItems={'center'} justifyContent={'center'} display={'flex'}>
                    <img style={{ marginRight: '16px', height: '20px', width: '20px' }} src={WarningImg} alt="" />
                    Aviso
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ferramentas de IA podem gerar informações equivocadas, considere checar sempre informações importantes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth variant="contained" onClick={() => setOpenDialogAvisoIa(false)}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default LinhaDoTempo;

function converterSegundosParaMinutos(segundosparams) {
    try {
        if (segundosparams == 0) {
            return `0 min e 0 seg`;
        }
        if (segundosparams > 60) {
            const minutos = Math.floor(segundosparams / 60);
            const segundos = Math.floor(segundosparams % 60);
            if (minutos > 1) {
                return `${minutos} Minutos e ${segundos} segundos`;
            } else {
                return `${minutos} Minuto e ${segundos} Segundos`;
            }
        } else {
            let segundos = Math.floor(segundosparams);
            if (segundos > 0) {
                return `${segundos} Segundo`;
            } else {
                return `${segundos} Segundos`;
            }
        }
    } catch (error) {
        toast.error('Não foi possível calcular tempo do audio processado.');
        console.error(error);
        return 'Error';
    }
}

function ReponseTypeDialog(props) {
    const { popupConfirma, onClose, selectedValue, open, onSelected, tiposResumo } = props;
    const theme = useTheme();
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onSelected(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle
                sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar + 1, background: '#fff' }}
                textAlign={'center'}
            >
                {popupConfirma ? 'Confirme o tipo de consulta' : 'Selecione o tipo de consulta'}
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                {tiposResumo.map((tipoResumo) => (
                    <ListItem disableGutters key={tipoResumo.value}>
                        <ListItemButton onClick={() => handleListItemClick(tipoResumo)}>
                            <ListItemText primary={tipoResumo.name} secondary={tipoResumo.description} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

function DialogTipoProtocolos(props) {
    const { onClose, open, onSelected, tiposProtocolos } = props;
    const theme = useTheme();

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        console.log(value);
        onSelected(value);
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle
                sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar + 1, background: '#fff' }}
                textAlign={'center'}
            >
                Selecione o tipo de protocolo que gostaria de gerar
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                {tiposProtocolos.map((tipoResumo) => (
                    <ListItem disableGutters key={tipoResumo.value}>
                        <ListItemButton onClick={() => handleListItemClick(tipoResumo)}>
                            <ListItemText primary={tipoResumo.name} secondary={tipoResumo.description} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

function SelectMicDialog({ onClose, values, open, onSelected, selectedValue }) {
    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle textAlign={'center'}>Selecione qual disposivo de microfone deseja utilizar:</DialogTitle>
            <List sx={{ pt: 0 }} dense>
                {values
                    .reduce((acc, current) => {
                        if (!acc.find((item) => item.deviceId === current.deviceId)) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, [])
                    .map((mic) => (
                        <ListItem disableGutters key={mic.deviceId}>
                            <ListItemButton onClick={() => onSelected(mic)}>
                                <ListItemIcon>
                                    {selectedValue && selectedValue.deviceId == mic.deviceId ? (
                                        <RadioButtonCheckedIcon />
                                    ) : (
                                        <RadioButtonUncheckedIcon />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={mic.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Dialog>
    );
}

function TestMicDialog({ onClose, open, dispositivoSelecionado, setError }) {
    const theme = useTheme();
    const { isRecording, startTestRecording, stopRecording } = useMic();
    const [loading, setLoading] = useState(false);
    const [transcricaoGerada, setTranscricaoGerada] = useState(null);

    const gravarOuPausar = useCallback(async () => {
        try {
            setLoading(true);
            setTranscricaoGerada(null);
            if (isRecording) {
                const blob = await stopRecording();
                const nameBlob = URL.createObjectURL(blob);
                console.log('[Teste MIC] Blob name file', nameBlob);
                const nomeSemDominio = nameBlob.replace(`blob:${window.location.origin}/`, '');
                const duracao = await obterDuracaoAudio(blob);
                const novoAudio = new AudioR1(nomeSemDominio + '.mp3', 'teste', 'local', new Date(), blob, duracao);
                console.log('[Teste MIC] AudioR1 gravado', novoAudio);

                try {
                    const transcricao = await enviaTesteAudio(novoAudio.blob, novoAudio.name, novoAudio.atendimentoId);
                    toast.success('[Teste MIC] Transcrição gerada com sucesso!');
                    setTranscricaoGerada(transcricao.message);
                } catch (error) {
                    toast.error('[Teste MIC] Erro ao salvar audio de teste!');
                    console.error(error);
                }

                return;
            }

            if (!dispositivoSelecionado) return toast.error('Nenhuma dispositivo de microfone selecionado!');
            await startTestRecording(dispositivoSelecionado.deviceId);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [dispositivoSelecionado, isRecording, startTestRecording, stopRecording]);

    const fecharClicandoFora = useCallback(() => {
        if (isRecording) {
            stopRecording();
        }
        setLoading(false);
        setTranscricaoGerada(null);
        onClose();
    }, [isRecording]);

    return (
        <Dialog open={open} onClose={fecharClicandoFora}>
            <DialogTitle textAlign={'center'}>Teste de Microfone</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Este teste tem como objetivo assegurar a qualidade do áudio antes do início da sua gravação. Para isso, siga
                    as instruções abaixo:
                </DialogContentText>
                <Box display="flex" flexDirection={'column'}>
                    <Box marginTop={'20px'}>
                        <Typography variant="body2" fontWeight={600}>
                            Para iniciar o teste, clique no botão abaixo e leia o texto e apos isso pare a gravação.
                        </Typography>
                    </Box>

                    {loading ? (
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100px'}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box
                            marginTop={'10px'}
                            display="flex"
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'column'}
                        >
                            <Box>
                                {!isRecording ? (
                                    <Button
                                        title="Começar a gravação do audio de teste"
                                        variant="contained"
                                        startIcon={<MicIcon />}
                                        onClick={gravarOuPausar}
                                    >
                                        {transcricaoGerada ? 'Gavar novamente' : 'Iniciar gravação do audio'}
                                    </Button>
                                ) : (
                                    <Box display={'flex'} gap={'5px'} alignItems={'center'}>
                                        <div
                                            style={{
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '50%',
                                                background: theme.palette.error.main,
                                            }}
                                            className="pulse-red"
                                        ></div>
                                        <span style={{ fontWeight: 600, color: theme.palette.error.main }}>Gravando...</span>
                                        <Button
                                            title="Pausar a gravação do audio de teste"
                                            variant="contained"
                                            color="error"
                                            startIcon={<PauseIcon />}
                                            onClick={gravarOuPausar}
                                        >
                                            Pausar Gravação
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            <Box
                                bgcolor={theme.palette.primary.main + '20'}
                                borderRadius={'8px'}
                                margin={'10px 0px'}
                                padding={'20px 10px'}
                                boxShadow={` 0px 0px 5px 0px ${theme.palette.primary.main + 20}`}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{ textAlign: 'center' }}
                                    fontWeight={600}
                                    color={theme.palette.primary.main}
                                >
                                    "Ao longo da carreira médica, é crucial manter-se atualizado com os avanços científicos e
                                    tecnológicos para oferecer o melhor cuidado aos pacientes. A constante busca pelo conhecimento
                                    é fundamental para garantir práticas clínicas eficazes e seguras."
                                </Typography>
                            </Box>
                            {transcricaoGerada != null && (
                                <Box
                                    bgcolor={theme.palette.primary.main + '20'}
                                    borderRadius={'8px'}
                                    margin={'10px 0px'}
                                    padding={'20px 10px'}
                                    boxShadow={` 0px 0px 5px 0px ${theme.palette.primary.main + 20}`}
                                >
                                    <Typography variant="body2" fontWeight={600}>
                                        Transcrição gerada a partir do seu audio, verifique se a transcrição está correta de
                                        acordo com oque foi lido no texto guia:
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ textAlign: 'center', marginTop: '10px' }}
                                        fontWeight={600}
                                        color={theme.palette.primary.main}
                                    >
                                        {`${transcricaoGerada ? transcricaoGerada : 'Nenhum texto gerado'}`}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}

async function atualizaByNameBlobInIndexDB(name, object) {
    return new Promise(async (resolve, reject) => {
        db.audios
            .where('name')
            .equals(name)
            .modify(object)
            .then(function () {
                console.log('item atualizado');
                resolve();
            })
            .catch(function (error) {
                console.log('erro ao tentar atualizar item');
                console.log(error);
                reject(error);
            });
    });
}

async function selectByBlob(atendimentoId) {
    return new Promise(async (resolve, reject) => {
        try {
            const result = await db.audios.where('atendimentoId').equals(atendimentoId).toArray();
            resolve(result);
        } catch (error) {
            console.log('Error ao tentar filtrar informações do indexedDB');
            console.error(error);
            reject(error);
        }
    });
}

function repleceall(text, search, replacement) {
    return text.split(search).join(replacement);
}
