import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokenLocalStorage } from '../config/tokenManager';
import { getUsuario } from '../services/usuario';
import { toast } from 'react-toastify';
import { getAtendimentos } from '../services/atendimento';

export const MainContext = React.createContext(null);

export function useMain() {
    return React.useContext(MainContext);
}

export function MainProvider({ children }) {
    const location = useLocation();
    const [token, setToken] = React.useState(getTokenLocalStorage());
    const [usuario, setUsuario] = React.useState(null);
    const [globalIsRecording, setGlobalIsRecording] = React.useState(false);
    const [atendimentos, setAtendimentos] = React.useState([]);
    const [inIFrame, setInIFrame] = React.useState(false);
    const carregaUsuario = useCallback(async () => {
        if (token) {
            try {
                const usuario = await getUsuario();
                setUsuario(usuario);
            } catch (error) {
                console.error(error);
                if (error.response.status === 401) {
                    location.navigate('/login');
                }
            }
        } else {
            setUsuario(null);
            setToken(null);
        }
    }, [token]);

    async function carregaAtendimentos() {
        try {
            const atendimentos = await getAtendimentos();
            setAtendimentos(atendimentos.sort((a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao)));
        } catch (error) {
            console.error(error);
            toast.error('Erro ao carregar atendimentos');
        }
    }

    useEffect(() => {
        if (!token) {
            toast.error('Você não está logado');
            location.navigate('/login', { replace: true });
        } else {
            carregaUsuario();
            carregaAtendimentos();
        }
    }, [token, location]);

    return (
        <MainContext.Provider
            value={{
                token,
                carregaUsuario,
                usuario,
                globalIsRecording,
                setGlobalIsRecording,
                atendimentos,
                carregaAtendimentos,
                inIFrame,
                setInIFrame,
            }}
        >
            {children}
        </MainContext.Provider>
    );
}
