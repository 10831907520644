import { Avatar, Box, Button, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { Saldo } from '../menuLateral/MenuLateral.styles';
import { useMain } from '../../../contexts/MainContext';
import MenuIcon from '@mui/icons-material/Menu';
import { deepOrange, deepPurple } from '@mui/material/colors';
import React from 'react';
export default function MenuTopMobile({ setOpenFormAssinaServico, setOpenDrawer, logout }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const { usuario, globalIsRecording, atendimentos, carregaAtendimentos } = useMain();
    const theme = useTheme();

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }
    return (
        <Box
            display={globalIsRecording ? 'none' : 'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{
                width: 'calc(100% - 40px)',
                margin: '10px',
                padding: '10px',
                borderRadius: '20px',
                background: '#fff',
                boxShadow: '0px 0px 10px -5px rgb(0 0 0 / 44%)',
            }}
        >
            <Box
                p={1}
                borderRadius={50}
                display={'flex'}
                justifyContent={'cneter'}
                alignContent={'center'}
                sx={{ background: theme.palette.primary.dark, cursor: 'pointer' }}
                onClick={() => setOpenDrawer(true)}
            >
                <MenuIcon style={{ color: '#ffffff' }} />
            </Box>
            <Box display={'flex'} alignItems={'center'} onClick={handleClick} sx={{ cursor: 'pointer' }}>
                <Typography variant="body1">{usuario?.name}</Typography>
                <Avatar sx={{ bgcolor: deepOrange[500], width: 30, height: 30, ml: 1 }}>{usuario?.name?.charAt(0)}</Avatar>
            </Box>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
                <MenuItem>
                    <Saldo
                        onClick={() => setOpenFormAssinaServico(true)}
                        title={`Seu saldo é ${
                            usuario?.corporativo?.saldo || usuario?.saldo
                        } e você pode comprar mais saldo clicando aqui!`}
                        color={usuario?.corporativo?.saldo >= 5 || usuario?.saldo >= 5 ? 'green' : 'red'}
                    >
                        Saldo: {`${usuario?.corporativo?.saldo || usuario?.saldo} atentimentos`}
                    </Saldo>
                </MenuItem>
                <MenuItem>
                    <Button
                        sx={{ color: '#161616', background: '#f4f4f4', ':hover': { background: '#f4f4f4' }, mt: 1 }}
                        variant="contained"
                        size="small"
                        fullWidth
                        onClick={logout}
                    >
                        Sair
                    </Button>
                </MenuItem>
            </Menu>
        </Box>
    );
}
