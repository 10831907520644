import React from 'react';

import { Container } from './EsqueciSenha.styles';
import { useNavigate } from 'react-router-dom';
import { gerarLinkEsqueciSenha } from '../../services/auth';
import { toast } from 'react-toastify';
import { Box, Button, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function EsqueciSenha() {
    const [loading, setLoading] = React.useState(false);
    async function cadastrar(e) {
        try {
            setLoading(true);
            e.preventDefault();
            const formData = new FormData(e.target);
            const { email } = Object.fromEntries(formData);
            await gerarLinkEsqueciSenha(email);
            toast.success('Link enviado para o email! Por favor verifique sua caixa de entrada e caixa de spam.');
        } catch (error) {
            if (error.response.status === 401) {
                toast.error('Email ou senha inválidos');
            } else {
                toast.error('Erro ao tentar enviar link de recuperação de senha. Tente novamente mais tarde.');
            }
        } finally {
            setLoading(false);
        }
    }
    return (
        <Container>
            <Box component={'form'} name="esqueci" onSubmit={cadastrar} autoComplete="off">
                <h1>Digite o email cadastrado</h1>
                <TextField
                    id="Email"
                    name="email"
                    autoComplete="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    fullWidth
                />
                <Button variant="outlined" type="submit" fullWidth>
                    Enviar Link Para Email
                </Button>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default EsqueciSenha;
