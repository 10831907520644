import api from './api';

export async function fazVenda(data) {
    const response = await api.post('/venda', data);
    return response.data;
}
export async function fazVendaOpen(data) {
    const response = await api.post('/venda/open', data);
    return response.data;
}
