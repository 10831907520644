import React, { useRef } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    TextField,
    Typography,
    Backdrop,
    CircularProgress,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import logo from '../../assets/r1medlogo_small.png';
import { useNavigate } from 'react-router-dom';
import {
    AiIco,
    BoxMainApresentacao,
    CloseIcon,
    CofIco,
    Content1,
    ContentVideo,
    DipIco,
    DivApresentaocaoVideo,
    DivIconesApresentacao,
    DivIconesApresentacaoVantagensPaciente,
    Header,
    JustIco,
    MicIco,
    SmilleIco,
    TitleSimulacao,
} from '../apresentacao/apresentacao.style';
import { lightThemeMui } from '../../config/themes/light.theme';

import { enviaContato } from '../../services/contato';
import { toast } from 'react-toastify';

import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

import { purple } from '@mui/material/colors';
import { register } from '../../services/auth';
import { saveTokenLocalStorage } from '../../config/tokenManager';

export default function Conversao() {
    const location = useLocation();

    React.useEffect(() => {
        console.log('Ativando Pixel analytics');
        ReactPixel.pageView();

        console.log('Ativando Google analytics.');
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    const theme = useTheme();
    const navegate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:800px)');

    const [contatoInfo, setContatoInfo] = React.useState({
        Nome: '',
        Celular: '',
        Mensagem: '',
    });

    const [loading, setLoading] = React.useState(false);
    const [openQuemSomos, setOpenQuemSomos] = React.useState(false);
    const [openPoliticaPrivacidade, setOpenPoliticaPrivacidade] = React.useState(false);
    const [openContato, setOpenContato] = React.useState(false);
    const colorPurple = purple;
    const refInputForm = useRef({});
    async function cadastrar(e) {
        setLoading(true);
        try {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { nome, email, senha, senha2, PhoneNumber } = Object.fromEntries(formData);

            if (senha !== senha2) {
                toast.error('Senhas não conferem! Por favor, tente novamente.');
                setLoading(false);
                return;
            }

            const regex = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{6,128}$/;

            if (!regex.test(senha)) {
                toast.error(
                    'A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
                );
                setLoading(false);
                return;
            }

            const token = await register(nome, email, senha, PhoneNumber);
            saveTokenLocalStorage(token);
            setLoading(false);
            try {
                console.log('Acionando evento de Regigistro no Pixel analytics e Tag Manager');
                ReactPixel.track('CompleteRegistration');
                window.dataLayer.push({
                    event: 'gtm.inscrito',
                    title: 'Inscrito',
                    price: '00,00',
                });
            } catch (error) {
                console.log('Erro ao ativar Pixel analytics');
            }
            navegate('/sucessocadastro', { replace: true });
        } catch (error) {
            if (error.response.status === 401) {
                toast.error('Email ou senha inválidos');
            } else {
                if (error?.response?.data?.errors?.length) {
                    error?.response?.data?.errors.forEach.call(error.response.data.errors, (erro) => {
                        toast.error(erro);
                    });
                    setLoading(false);
                    return;
                }
                setLoading(false);
                toast.error('Erro ao tentar criar conta!');
            }
        }
    }

    const FormCadastro = () => (
        <Box maxWidth={750}>
            <Box component={'form'} name="cadastro" onSubmit={cadastrar} autoComplete="off">
                <Typography
                    textAlign={'center'}
                    color={theme.palette.primary.dark}
                    variant="h1"
                    fontSize={isSmallScreen ? 44 : 52}
                    fontWeight="bold"
                    children={'Cadastre-se e experimente 10 consultas gratuitamente.'}
                    mb={1}
                />
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={1}>
                    <Typography
                        variant="caption"
                        textAlign={'center'}
                        color={theme.palette.primary.dark}
                        children={'Não pedimos cartão de crédito'}
                        fontWeight={'bold'}
                    />
                </Box>
                <TextField
                    id="Nome"
                    name="nome"
                    label="Nome"
                    autoComplete="name"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    size="small"
                    focused
                    sx={{ mb: 2 }}
                    defaultValue={refInputForm?.current?.Nome}
                    onChange={({ target }) => {
                        return (refInputForm.current.Nome = target.value);
                    }}
                />
                <TextField
                    id="Email"
                    name="email"
                    autoComplete="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    fullWidth
                    size="small"
                    focused
                    sx={{ mb: 2 }}
                    defaultValue={refInputForm?.current?.Email}
                    onChange={({ target }) => {
                        return (refInputForm.current.Email = target.value);
                    }}
                />
                <TextField
                    id="PhoneNumber"
                    name="PhoneNumber"
                    autoComplete="tel"
                    maxlength="11"
                    label="Celular com DDD"
                    placeholder="Digite apenas numeros sem espaços, ex: 21988888888"
                    variant="outlined"
                    inputProps={{
                        maxLength: 11,
                        'x-autocompletetype': 'tel',
                        pattern: '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
                        autoComplete: 'tel',
                    }}
                    title="Digite apenas numeros sem espaços"
                    helperText="Celular com DDD apenas numeros sem espaços"
                    type="tel"
                    required
                    fullWidth
                    size="small"
                    focused
                    sx={{ mb: 2 }}
                    defaultValue={refInputForm?.current?.Celular}
                    onChange={({ target }) => {
                        return (refInputForm.current.Celular = target.value);
                    }}
                />
                <TextField
                    id="Senha"
                    name="senha"
                    label="Senha"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    helperText="A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
                    size="small"
                    focused
                    sx={{ mb: 2 }}
                    defaultValue={refInputForm?.current?.Senha}
                    onChange={({ target }) => {
                        return (refInputForm.current.Senha = target.value);
                    }}
                />
                <TextField
                    id="Senha2"
                    name="senha2"
                    label="Repita a Senha"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    helperText="A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
                    size="small"
                    focused
                    sx={{ mb: 2 }}
                    defaultValue={refInputForm?.current?.Senha2}
                    onChange={({ target }) => {
                        return (refInputForm.current.Senha2 = target.value);
                    }}
                />
                <Button variant="contained" sx={{ textTransform: 'uppercase' }} type="submit" fullWidth>
                    experimentar 10 consultas gratuitas
                </Button>
            </Box>
        </Box>
    );

    //#region COMPONENTS REPETIDOS

    async function EnviarContato(event) {
        setLoading(true);
        try {
            event.preventDefault();
            const { Nome, Celular, Mensagem } = contatoInfo;
            await enviaContato(Nome, Celular, Mensagem);
            toast.success('Mensagem enviado com sucesso');
            setOpenContato(false);
            setContatoInfo({
                Nome: '',
                Celular: '',
                Mensagem: '',
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro ao enviar email.');
        }
        setLoading(false);
    }

    //#endregion
    return (
        <BoxMainApresentacao>
            <Header id="HEADER" display={'flex'} justifyContent={'space-between'}>
                <img src={logo} />
            </Header>
            {isSmallScreen ? (
                <Content1 id="CONTENT_1">
                    <DivApresentaocaoVideo>
                        <Box flex={1} maxWidth={'750px'} mr={2} display={'flex'} flexDirection={'column'}>
                            <Typography
                                variant="body1"
                                fontSize={isSmallScreen ? 16 : 22}
                                children={'OTIMIZE SUAS CONSULTAS COM IA'}
                            />
                            <Typography
                                variant="h1"
                                fontSize={isSmallScreen ? 44 : 52}
                                fontWeight="bold"
                                children={'Economize seu tempo no registro do prontuário do paciente.'}
                                mb={2}
                            />
                            <ContentVideo>
                                <iframe
                                    width={'100%'}
                                    height={'100%'}
                                    src="https://www.youtube.com/embed/5vSQJYhFuKs?si=rh7zSZjU3gvjAyx6"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </ContentVideo>
                            <Typography
                                variant="body1"
                                fontSize={isSmallScreen ? 16 : 20}
                                fontWeight={400}
                                mt={2}
                                color={'#6f6f6f'}
                                children={
                                    'Que tal um R1 para chamar de seu? Tenha seu assistente pessoal com Inteligência Artificial para documentação clínica automatizada.'
                                }
                                mb={2}
                            />
                        </Box>
                    </DivApresentaocaoVideo>
                </Content1>
            ) : (
                <Content1 id="CONTENT_1">
                    <Box display={'flex'} flex={1}>
                        <DivApresentaocaoVideo>
                            <Box flex={1} maxWidth={'750px'} mr={2}>
                                <Typography
                                    variant="body1"
                                    fontSize={isSmallScreen ? 18 : 22}
                                    children={'OTIMIZE SUAS CONSULTAS COM IA'}
                                />
                                <Typography
                                    variant="h1"
                                    fontSize={isSmallScreen ? 46 : 52}
                                    fontWeight="bold"
                                    children={'Economize seu tempo no registro do prontuário do paciente.'}
                                    mb={4}
                                />
                                <Typography
                                    variant="body1"
                                    fontSize={isSmallScreen ? 16 : 20}
                                    fontWeight={400}
                                    mt={1}
                                    color={'#6f6f6f'}
                                    children={
                                        'Que tal um R1 para chamar de seu? Tenha seu assistente pessoal com Inteligência Artificial para documentação clínica automatizada.'
                                    }
                                    mb={4}
                                />
                            </Box>
                            <ContentVideo>
                                <iframe
                                    width={'100%'}
                                    height={'100%'}
                                    src="https://www.youtube.com/embed/5vSQJYhFuKs?si=rh7zSZjU3gvjAyx6"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </ContentVideo>
                        </DivApresentaocaoVideo>
                    </Box>
                </Content1>
            )}
            <Box
                sx={{ background: colorPurple[100] }}
                display={'flex'}
                flex={1}
                width={isSmallScreen ? 'unset' : '100%'}
                p={2}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <FormCadastro />
            </Box>
            <Box pt={2} pb={2} pr={4} pl={4} flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'} mb={4}>
                <Typography
                    variant="h3"
                    mb={4}
                    children={'O copiloto perfeito para suas consultas'}
                    fontSize={30}
                    fontWeight="bold"
                    textAlign={'center'}
                />
                <Typography
                    variant="h4"
                    children={'Transforme agora mesmo a forma como você oferece assistência médical!'}
                    color={'#6f6f6f'}
                    fontWeight="bold"
                    fontSize={18}
                    textAlign={'center'}
                />
            </Box>
            <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} mt={3} mb={3}>
                <Box display={'flex'} gap={3} maxWidth={750} flexWrap={'wrap'} justifyContent={'center'}>
                    <DivIconesApresentacao>
                        <MicIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Captura e compreende o contexto da conversa entre médicos e pacientes.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <AiIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Modelos de IA generativa treinados em milhares de consultas.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <JustIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Maior segurança jurídica para o médico, clínicas e hospitais.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <DipIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'R1 produz em segundos o registro completo do prontuário do paciente, com excelência.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <CofIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Modelo de preço acessível e adequado à sua demanda.'}
                        />
                    </DivIconesApresentacao>
                    <DivIconesApresentacao>
                        <SmilleIco color={lightThemeMui.palette.primary.light} style={{ width: '40px', height: '40px' }} />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Consultas mais focadas e pacientes mais satisfeitos.'}
                        />
                    </DivIconesApresentacao>
                </Box>
            </Box>
            <Box pt={2} pb={2} pr={4} pl={4} flex={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                <Typography
                    variant="h4"
                    children={'Resultados expressivos'}
                    color={'#6f6f6f'}
                    fontWeight="bold"
                    fontSize={18}
                    textAlign={'center'}
                />
                <Typography
                    variant="h3"
                    mb={4}
                    children={'Impactos positivos para médicos e pacientes:'}
                    fontSize={30}
                    fontWeight="bold"
                    textAlign={'center'}
                />
            </Box>
            <Box
                id="CONTENT_3"
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                mb={1}
                pt={2}
                pb={2}
                pr={4}
                pl={4}
                flex={1}
            >
                <Box
                    display={'flex'}
                    gap={3}
                    maxWidth={1080}
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    mb={2}
                >
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'65%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Redução de tempo de preenchimento do prontuário.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'70%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Redução da sensação de esgotamento ao final do dia.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'75%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Dos médicos referiram melhora expressiva na qualidade do prontuário.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                    <DivIconesApresentacaoVantagensPaciente>
                        <Typography
                            variant="body1"
                            fontSize={40}
                            color={lightThemeMui.palette.primary.light}
                            textAlign={'center'}
                            mt={1}
                            children={'80%'}
                        />
                        <Typography
                            variant="subtitle2"
                            textAlign={'center'}
                            mt={2}
                            children={'Dos pacientes referiram ter recebido maior atenção do médico durante a consulta.'}
                        />
                    </DivIconesApresentacaoVantagensPaciente>
                </Box>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                alignItems={'center'}
                pt={2}
                pb={2}
                pr={4}
                pl={4}
            >
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'fit-content'}>
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer' }}
                        children={'Contato'.toUpperCase()}
                        onClick={() => setOpenContato(true)}
                    />
                    <Divider orientation="vertical" flexItem sx={{ mr: 0.5, ml: 0.5 }} />
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpenQuemSomos(true)}
                        children={'Quem somos'.toUpperCase()}
                    />
                    <Divider orientation="vertical" flexItem sx={{ mr: 0.5, ml: 0.5 }} />
                    <Typography variant="caption" sx={{ cursor: 'pointer' }} children={'Termos de uso'.toUpperCase()} />
                    <Divider orientation="vertical" flexItem sx={{ mr: 0.5, ml: 0.5 }} />
                    <Typography
                        variant="caption"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpenPoliticaPrivacidade(true)}
                        children={'Política de privacidade'.toUpperCase()}
                    />
                </Box>
                <Typography variant="caption" children={'(c) 2023 - 2024 R1 MED Tecnologia'} />
            </Box>
            <Dialog onClose={() => setOpenQuemSomos(false)} open={openQuemSomos} PaperProps={{ sx: { maxWidth: 'unset' } }}>
                <TitleSimulacao position={'relative'}>
                    <Typography variant="body1" textAlign={'center'} fontSize={20}>
                        Quem somos
                    </Typography>
                    <CloseIcon onClick={() => setOpenQuemSomos(false)} />
                </TitleSimulacao>
                <DialogContent>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        R1MED: transformando consultas médicas através de inteligência artificial
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Bem-vindo ao R1MED, uma iniciativa revolucionária desenvolvida pelos médicos Carlos Lopes, Pedro Baldaque
                        e Ronaldo Carneiro, com o objetivo de transformar a maneira como consultas médicas são realizadas no
                        Brasil.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Dr Carlos Lopes é médico nutrólogo, com extensão em tratamento de obesidade pela Universidade de Harvard
                        (EUA), com mais de 18 anos de experiência na área de tecnologia em saúde, atuando desde 2005 como CEO de
                        uma das maiores empresas do Brasil no segmento de prontuário eletrônico.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Dr Pedro Baldaque é médico gastroenterologista com mestrado em medicina pelo Departamento de Clínica
                        Médica do HUCFF-UFRJ e coordenador dos cursos de pós-graduação em gastroenterologia da Universidade
                        Castelo Branco, Instituto Carlos Chagas e Centro Universitário Celso Lisboa. É chefe do serviço de
                        Gastroenterologia do Hospital da Gamboa e CEO do Instituto de Gastroenterologia do Rio de Janeiro, com
                        larga experiência no desenvolvimento de novos projetos na área médica.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Dr Ronaldo Carneiro é gastroenterologista, mestre em Ciências Médicas pela UERJ-PGCM), professor e
                        coordenador adjunto de Pós-Graduação em Gastroenterologia, ex-Coordenador Académico de cursos de Medicina
                        no Rio de Janeiro/RJ e em Salvador/BA, membro do Departamento médico-científico na indústria farmacêutica
                        e grande entusiasta do impacto transformador da tecnologia na Medicina contemporânea.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Nossa inspiração: enfrentando os desafios na Saúde brasileira
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        O grande motivador para criação da R1MED foi a observação do quanto rotinas burocráticas e de documentação
                        médica ocupam tempo precioso do médico, que já convive com burnout e jornadas de trabalho intensas.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        {' '}
                        Além disso o aumento significativo nos processos judiciais relacionados à saúde no Brasil também
                        demonstrou o quanto a falta de tempo e a documentação clínica ineficiente podem causar problemas na esfera
                        cível e criminal. Questões como demora no atendimento, falta de empatia dos profissionais de saúde e o
                        esgotamento emocional dos mesmos impulsionaram a nossa busca por soluções inovadoras.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        R1MED: uma Ferramenta de inteligência artificial para transformar consultas médicas
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Desenvolvemos o R1MED, um aplicativo inovador que combina inteligência artificial conversacional com as
                        mais avançadas tecnologias de inteligência artificial generativa. Disponível desde novembro para clínicas
                        selecionadas em fase de testes, a ferramenta está disponível para médicos, clínicas e hospitais de todo
                        Brasil a partir de janeiro de 2024.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Uma revolução no atendimento médico: principais características do R1MED
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Automatização da documentação do prontuário médico: o R1MED é um assistente de inteligência artificial que
                        automatiza a documentação clínica, permitindo que os médicos se dediquem mais ao paciente e otimizem o
                        tempo gasto com tarefas burocráticas.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Eficiência operacional: desenvolvido para acelerar o atendimento, o R1MED reduz significativamente o tempo
                        de preenchimento do prontuário, alinhando-se com as demandas da área médica.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Impacto na qualidade do atendimento: nosso objetivo é reduzir em até 65% o tempo dedicado à documentação,
                        melhorando a qualidade da atenção ao paciente durante a consulta e combatendo o esgotamento dos
                        profissionais de saúde.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Compromisso com a segurança e padronização: impacto legal e ambiental
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Além de proporcionar maior segurança jurídica, padronização na documentação do prontuário e aprimorar o
                        ambiente de trabalho dos profissionais de saúde, o R1MED contribui para a melhoria expressiva na qualidade
                        do prontuário, crucial para a continuidade do tratamento e prevenção de processos judiciais por erro
                        médico.
                    </Typography>
                    <Typography variant="body1" mb={2} fontWeight={'bolder'}>
                        Flexibilidade para diferentes cenários: treinamento personalizado
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Os modelos de inteligência artificial do R1 podem ser treinados para diversos cenários, abrangendo desde
                        atendimento ambulatorial até situações emergenciais e cuidados domiciliares.
                    </Typography>
                    <Typography variant="body1" mb={2}>
                        Junte-se a nós na revolução do atendimento médico. Com o R1MED, estamos comprometidos em transformar a
                        experiência em saúde, trazendo eficiência, empatia e qualidade para o centro de cada consulta.
                    </Typography>
                </DialogContent>
            </Dialog>
            <Dialog
                onClose={() => setOpenPoliticaPrivacidade(false)}
                open={openPoliticaPrivacidade}
                PaperProps={{ sx: { maxWidth: 'unset' } }}
            >
                <TitleSimulacao position={'relative'}>
                    <Typography variant="body1" textAlign={'center'} fontSize={20}>
                        Política de privacidade
                    </Typography>
                    <CloseIcon onClick={() => setOpenPoliticaPrivacidade(false)} />
                </TitleSimulacao>
                <DialogContent>
                    <h2>
                        <strong>Sua privacidade é importante para nós!</strong>
                    </h2>
                    Este site é gerido e administrado pela MEDX TECNOLOGIA S/A, que desenvolve um trabalho contínuo de garantia da
                    proteção dos dados pessoais fornecidos pelo usuário, tratando a informação nos termos previstos na lei
                    brasileira LGPD (Lei Geral de Proteção de Dados) nº13.709/2018, assim como a lei europeia GDPR (General Data
                    Protection Regulation) (EU) 2016/679 de 27 de abril de 2016. Também aplicamos as melhores práticas de
                    cibersegurança e proteção de dados, descritas na ISO/IEC 27001:2013, ISO/IEC 27701:2019, ENISA, NIST CSF, Cert
                    RMM, entre outras boas práticas de mercado. Esta política de privacidade descreve: 1. Quem é o responsável
                    pelos meus dados pessoais? 2. Quais dados pessoais são tratados pela MEDX? E porquê? 3. Por quanto tempo os
                    meus dados pessoais serão conservados? 4. Como conservam os meus dados pessoais? 5. A que entidades podem ser
                    enviados os meus dados pessoais? 6. Quais são os meus direitos? 7. Como posso conhecer quaisquer alterações a
                    esta política de privacidade?
                    <h5>
                        <strong>1. Quem é o responsável pelos meus dados pessoais?</strong>
                    </h5>
                    Os seus dados serão tratados por <strong>MEDX TECNOLOGIA S/A CNPJ: 25.402.501/0001-25</strong>, Av João Cabral
                    de Melo Neto, 850 bloco 3 grupo 705 – Barra da Tijuca – Rio de Janeiro – RJ Poderá contatar-nos e ao nosso
                    responsável pela proteção dos seus dados através do e-mail dpo@medx.med.br
                    <h5>
                        <strong>2. Quais dados pessoais são tratados pela MEDX?</strong>
                    </h5>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <b>Origem de coleta</b>
                                </td>
                                <td>
                                    <b>Finalidade</b>
                                </td>
                                <td>
                                    <b>Fundamento Jurídico</b>
                                </td>
                                <td>
                                    <b>Dados pessoais coletados</b>
                                </td>
                                <td>
                                    <b>Prazo de conservação </b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>http://info.wp36f10.kinghost.net</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Comunicações</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}> Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Redes sociais</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Comunicações </span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}> Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>WhatsApp</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Comunicações</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}> Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>http://info.wp36f10.kinghost.net</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Análise de informações de navegação</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Consentimento (quando você aceita cookies)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Endereço IP</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>http://info.wp36f10.kinghost.net</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de assinatura</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato </span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>

                                    <span style={{ fontWeight: 400 }}>Celular</span>

                                    <span style={{ fontWeight: 400 }}>CPF / CNPJ</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Plataforma MEDX</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Prestação do serviço entre médico e paciente</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Telefone</span>

                                    <span style={{ fontWeight: 400 }}>Celular</span>

                                    <span style={{ fontWeight: 400 }}>CPF / CNPJ e</span>

                                    <span style={{ fontWeight: 400 }}>Dados de Saúde</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>20 anos</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://docflix.live</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de Assinatura</span>

                                    <span style={{ fontWeight: 400 }}>(acesso pago)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>CPF ou CNPJ</span>

                                    <span style={{ fontWeight: 400 }}>Endereço</span>

                                    <span style={{ fontWeight: 400 }}>CEP</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://docflix.live</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de Assinatura</span>

                                    <span style={{ fontWeight: 400 }}>(acesso gratuito)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Consentimento</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://medx.social</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de assinatura (trial)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span style={{ fontWeight: 400 }}>https://medx.social</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Adesão de assinatura (acesso pago)</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>Execução de um contrato</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>E-mail</span>

                                    <span style={{ fontWeight: 400 }}>Nome</span>

                                    <span style={{ fontWeight: 400 }}>CPF ou CNPJ</span>

                                    <span style={{ fontWeight: 400 }}>Endereço</span>

                                    <span style={{ fontWeight: 400 }}>CEP</span>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 400 }}>12 meses após término do contrato</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h5>
                        <strong>3. Por quanto tempo meus dados pessoais serão conservados?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        O período durante o qual os seus dados pessoais são armazenados e conservados varia de acordo com a
                        finalidade para a qual os dados foram recolhidos e estão definidos na tabela do item 2.
                    </span>
                    <h5>
                        <strong>4. Como conservam os meus dados pessoais?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Nossa obrigação é manter os seus dados seguros, e para isso aplicamos medidas de segurança apropriadas
                        para assegurar a proteção dos seus dados pessoais e impedir o acesso de pessoas não autorizadas. Estamos
                        em conformidade com a LGPD (Lei Geral de Proteção de Dados - Brasil) e GDPR (General Data Protection
                        Regulation - EU) além de aplicamos diversas práticas de cibersegurança e proteção de dados, tais como:
                        gestão de vulnerabilidades,  testes de invasão em nossos sistemas e tecnologias, conscientização dos
                        colaboradores com relação ao tema cibersegurança e proteção de dados, gestão e resposta a incidentes de
                        segurança, avaliação de impacto na privacidade (PIA), revisão e melhorias constantes nos processos
                        internos, entre outras práticas.
                    </span>
                    <h5>
                        <strong>5. A que entidades podem ser enviados os meus dados pessoais?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo. No entanto, o
                        tratamento dos seus dados pessoais poderá ser efetuado por prestadores de serviços, mas pediremos o seu
                        consentimento para tal.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                        Como a MEDX possui clientes em diversos países diferentes, tratamos os dados pessoais na sede localizada
                        no Brasil, assim como através de prestadores de serviço com a sede localizada dentro e fora do Brasil, o
                        que pode implicar um compartilhamento internacional dos seus dados. Tomaremos as salvaguardas necessárias
                        para garantir o tratamento seguro dos seus dados, através de contratos celebrados contendo cláusulas
                        contratuais e aplicando práticas de cibersegurança para garantir a segurança dos seus dados durante todo o
                        ciclo de vida.
                    </span>
                    <h5>
                        <strong>6. Quais são os meus direitos?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Como titular dos dados, você poderá exercer os seguintes direitos, nos limites legalmente estabelecidos:
                    </span>
                    <ul>
                        <li>
                            <b>Confirmação e acesso:</b>
                            <span style={{ fontWeight: 400 }}>
                                {' '}
                                Permite que você possa verificar se a MEDX trata dados pessoais seus e, em caso positivo,
                                solicitar uma cópia dos dados pessoais que nós temos sobre você.
                            </span>
                        </li>
                        <li>
                            <b>Correção:</b>
                            <span style={{ fontWeight: 400 }}>
                                {' '}
                                Permite que você solicite a correção dos seus dados pessoais incompletos, inexatos ou
                                desatualizados.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Anonimização, bloqueio ou eliminação: </b>
                            <span style={{ fontWeight: 400 }}>
                                Permite que você nos peça: (a) anonimizar seus dados, de forma a que eles não possam ser mais
                                relacionados a você e, portanto, deixem de ser dados pessoais; (b) bloquear seus dados,
                                suspendendo temporariamente a possibilidade de tratarmos; e (c) eliminar seus dados, caso em que
                                apagaremos todos seus dados sem possibilidade de reversão, salvo os casos previstos em lei.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Portabilidade: </b>
                            <span style={{ fontWeight: 400 }}>
                                Você tem o direito de solicitar, mediante requisição expressa, que a MEDX, forneça a você, ou a
                                terceiros que você escolher, os seus dados pessoais em formato estruturado ou interoperável, para
                                transferência a outro fornecedor, desde que não viole a propriedade intelectual ou segredo de
                                negócio da empresa. Da mesma forma, você pode pedir que outras empresas enviem à MEDX seus dados
                                pessoais para facilitar a contratação de nossos serviços.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Informação sobre compartilhamento: </b>
                            <span style={{ fontWeight: 400 }}>
                                Você tem o direito de saber as entidades com as quais a MEDX realiza o uso compartilhado de dados.
                                Manteremos no item 5. “A que entidades podem ser enviados meus dados pessoais?” desta política, a
                                indicação das modalidades e atividades que podem envolver compartilhamento de dados com terceiros.
                                Em todo o caso, se você tiver dúvidas ou quiser maiores detalhes, você tem o direito de nos
                                solicitar essas informações.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Informação sobre a possibilidade de não consentir: </b>
                            <span style={{ fontWeight: 400 }}>
                                Permite que você tenha informações claras e completas sobre a possibilidade e as consequências de
                                não fornecer consentimento. O seu consentimento, quando necessário, deve ser livre e informado.
                                Portanto, sempre que pedirmos seu consentimento você será livre para negá-lo. Ainda que nesses
                                casos tenhamos que limitar nossos serviços.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Revogação do consentimento: </b>
                            <span style={{ fontWeight: 400 }}>
                                Você tem o direito de retirar o seu consentimento em relação às atividades de tratamento que se
                                baseiam nessa base legal. No entanto, isso não afetará a legalidade de qualquer tratamento
                                realizado anteriormente. Se você retirar o seu consentimento, talvez não possamos fornecer
                                determinados serviços e experiências, mas iremos avisá-lo quando isso ocorrer.
                            </span>
                        </li>
                        <li aria-level="1">
                            <b>Oposição: </b>
                            <span style={{ fontWeight: 400 }}>
                                A lei autoriza o Tratamento de Dados Pessoais mesmo sem o seu consentimento ou um contrato
                                conosco. Nesse caso, é preciso demonstrar que há motivos legítimos para tratar seus dados, como
                                por exemplo, prevenir fraudes ou melhorar nossa comunicação com você. Caso você não concorde com
                                esse Tratamento, poderá se opor a ele, solicitando a interrupção.
                            </span>
                        </li>
                    </ul>
                    <span style={{ fontWeight: 400 }}>
                        Para exercer estes direitos, poderá enviar um pedido por escrito para o contato indicado na seção “
                    </span>
                    <i>
                        <span style={{ fontWeight: 400 }}>1. Quem é o responsável pelos meus dados pessoais?</span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                        ” e deverá indicar no seu pedido o seu nome e o direito que quer exercer.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                        Se considerar que foi cometida alguma violação da legislação em matéria de proteção de dados em vigor no
                        que respeita ao tratamento dos seus dados pessoais.
                    </span>
                    <h5>
                        <strong>7. Como posso conhecer quaisquer alterações a esta política de privacidade?</strong>
                    </h5>
                    <span style={{ fontWeight: 400 }}>
                        Como buscamos sempre melhorar nossos Serviços, esta Política de Privacidade pode sofrer atualizações para
                        refletir as melhorias realizadas. Desta forma, recomendamos a visita periódica a esta página para que você
                        tenha conhecimento sobre as modificações efetuadas.
                    </span>
                    <span style={{ fontWeight: 400 }}>
                        Reservamos o direito de modificar essa Política de Privacidade a qualquer momento. Alterações e
                        esclarecimentos vão surtir efeito imediatamente após sua publicação no site.
                    </span>
                    Data: 08/11/2023
                </DialogContent>
            </Dialog>
            <Dialog onClose={() => setOpenContato(false)} open={openContato} PaperProps={{ sx: { maxWidth: 'unset' } }}>
                <TitleSimulacao position={'relative'}>
                    <Typography variant="body1" textAlign={'center'} fontSize={20}>
                        Contato
                    </Typography>
                    <CloseIcon onClick={() => setOpenContato(false)} />
                </TitleSimulacao>
                <DialogContent>
                    <Box
                        component={'form'}
                        p={2}
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{ minWidth: 500 }}
                        onSubmit={(event) => EnviarContato(event)}
                    >
                        <TextField
                            value={contatoInfo.Nome}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Nome: target.value })}
                            sx={{ mb: 3 }}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Nome'}
                            placeholder="Informe seu nome."
                            required
                        />
                        <TextField
                            sx={{ mb: 3 }}
                            value={contatoInfo.Celular}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Celular: target.value })}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Telefone'}
                            placeholder="Informe um telefone para contato."
                            required
                            title="ex: 21988887777"
                            inputProps={{
                                maxLength: 20,
                                'x-autocompletetype': 'tel',
                                pattern: '^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$',
                                autoComplete: 'tel',
                            }}
                        />
                        <TextField
                            value={contatoInfo.Mensagem}
                            onChange={({ target }) => setContatoInfo({ ...contatoInfo, Mensagem: target.value })}
                            sx={{ mb: 3 }}
                            variant="outlined"
                            size="small"
                            focused
                            label={'Mensagem'}
                            multiline
                            rows={4}
                            placeholder="Digite aqui sua mensagem."
                            required
                        />
                        <Button variant="contained" type="submit">
                            Enviar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </BoxMainApresentacao>
    );
}
