import constantes from './constantes';

const sufix = '-token';

export const saveTokenLocalStorage = (token) => {
    localStorage.setItem(`${constantes.localStoragePrefix}${sufix}`, token);
};

export const getTokenLocalStorage = () => {
    return localStorage.getItem(`${constantes.localStoragePrefix}${sufix}`);
};

export const removeTokenLocalStorage = () => {
    localStorage.removeItem(`${constantes.localStoragePrefix}${sufix}`);
};
