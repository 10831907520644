import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    /* overflow-y: auto; */
`;

export const Propaganda = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;

    .item-1,
    .item-2,
    .item-3 {
        position: absolute;
        display: block;
        top: 2em;
        width: 50%;
        font-size: 2em;
        animation-duration: 20s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    .item-1 {
        animation-name: anim-1;
    }

    .item-2 {
        animation-name: anim-2;
    }

    .item-3 {
        animation-name: anim-3;
    }

    @keyframes anim-1 {
        0%,
        8.3% {
            left: -100%;
            opacity: 0;
        }
        8.3%,
        25% {
            left: 25%;
            opacity: 1;
        }
        33.33%,
        100% {
            left: 110%;
            opacity: 0;
        }
    }

    @keyframes anim-2 {
        0%,
        33.33% {
            left: -100%;
            opacity: 0;
        }
        41.63%,
        58.29% {
            left: 25%;
            opacity: 1;
        }
        66.66%,
        100% {
            left: 110%;
            opacity: 0;
        }
    }

    @keyframes anim-3 {
        0%,
        66.66% {
            left: -100%;
            opacity: 0;
        }
        74.96%,
        91.62% {
            left: 25%;
            opacity: 1;
        }
        100% {
            left: 110%;
            opacity: 0;
        }
    }
    @media screen and (max-width:800px){
        display:none;
    }
`;

export const ContainerOptions = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    background-color: #efefef85;
    backdrop-filter: blur(8px);
    z-index: 10;
    max-width: 500px;

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        width: 100%;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 100%;

            > span {
                color: #000;
                line-height: 2.5em;
                font-size: 1.2em;
                font-weight: 600;
            }
            > button {
            }
        }
    }
    > form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        width: 100%;
        gap: 20px;

        > h1 {
            font-size: 2em;
            margin-bottom: 1em;
            color: #000;
            margin-top: 0;
        }

        > a {
            color: #000;
            font-size: 0.8em;
            margin-bottom: 1em;
            texalign: center;
        }

        > div > .logo {
            width: 100%;
            max-width: 200px;
            margin: auto;
            margin-top: 2em;
        }
    }
`;
