import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    min-width: 50%;
    gap: 10px;
    > span {
        font-size: 1.1rem;
        font-weight: 600;
        margin-left: 5px;
        > b {
            color: #ff4d4d;
        }
    }
`;

export const ProtocoloContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
    box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    flex: 1;
    height: 100%;
    overflow: hidden;
    background: #70157f0f;

    > .headerProtocolo {
        display: flex;
        flex-direction: column;

        padding: 5px 10px;
        /* background-color: #f1f1f1; */
        border-bottom: 1px solid #f1f1f1;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        > div > h1 {
            font-size: 0.7rem;
            font-weight: 600;
            margin: 0px;
        }

        > div > h6 {
            font-size: 0.5rem;
            font-weight: 400;
            margin: 0px;
        }
    }

    > .bodyResumo {
        border: 1px solid #f1f1f1;
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 10px;
        flex: 1;
        overflow: hidden;
        height: 100%;
        overflow-y: auto;

        > article {
            font-size: 14px;

            > .headerMensagem {
                display: flex;
                align-items: center;
                background: #70157f26;
                padding: 10px;
                border-radius: 8px;
                justify-content: space-between;
            }

            .userMessage {
                padding: 10px;
                color: #70157f;
                font-weight: 600;
            }
        }
    }
`;
