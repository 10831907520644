import api from './api';

export async function saveAudio(blob, name, AtendimentoId, DataGravacao) {
    const form = new FormData();
    form.append('AtendimentoId', AtendimentoId);
    form.append('name', name);
    form.append('DataGravacao', DataGravacao);
    form.append('file', blob);
    console.log('===========POST AUDIO=======================');
    console.log(form);
    try {
        const result = await api.post('/Audio', form);
        console.log('===========RESPONSE POST AUDIO=======================');
        console.log(result);
        return result;
    } catch (error) {
        console.log('===========RESPONSE POST AUDIO=======================');
        console.error(error);
        return error;
    }
}
export async function saveAudioSalad(blob, name, AtendimentoId, DataGravacao) {
    const form = new FormData();
    form.append('AtendimentoId', AtendimentoId);
    form.append('name', name);
    form.append('DataGravacao', DataGravacao);
    form.append('file', blob);
    console.log('===========POST AUDIO=======================');
    console.log(form);
    try {
        const result = await api.post('/Audio/salad', form);
        console.log('===========RESPONSE POST AUDIO=======================');
        console.log(result);
        return result;
    } catch (error) {
        console.log('===========RESPONSE POST AUDIO=======================');
        console.error(error);
        return error;
    }
}

export async function enviaTesteAudio(blob, name) {
    const form = new FormData();
    form.append('name', name);
    form.append('file', blob);
    const result = await api.post('/Audio/testeAudio', form);
    return result.data;
}
