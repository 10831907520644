import { createTheme } from '@mui/material';

export const lightThemeMui = createTheme({
    palette: {
        primary: {
            main: '#70157F',
        },
        secondary: {
            main: '#4f4f4f',
        },
        mode: 'light',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: 'capitalize',
                },
            },
        },
    },
});
