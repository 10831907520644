export const storeSchema = {
    name: 'audios',
    keyPath: 'id',
    autoIncrement: true,
    indexes: [
        {
            name: 'name',
            keyPath: 'name',
            options: { unique: true },
        },
        {
            name: 'atendimentoId',
            keyPath: 'atendimentoId',
            options: { unique: false },
        },
        {
            name: 'status',
            keyPath: 'status',
            options: { unique: false },
        },
        {
            name: 'date',
            keyPath: 'date',
            options: { unique: false },
        },
        {
            name: 'blob',
            keyPath: 'blob',
            options: { unique: false },
        },
        {
            name: 'duracao',
            keyPath: 'duracao',
            options: { unique: false },
        },
    ],
};

export const DBConfig = {
    name: 'R1-LOCAL-DB',
    version: 3,
    objectStoresMeta: [
        {
            store: storeSchema.name,
            storeConfig: { keyPath: storeSchema.keyPath, autoIncrement: storeSchema.autoIncrement },
            storeSchema: storeSchema.indexes,
        },
    ],
};

export class AudioR1 {
    constructor(name, atendimentoId, status, date, blob, duracao) {
        this._name = name;
        this._atendimentoId = atendimentoId;
        this._status = status;
        this._date = date;
        this._blob = blob;
        this._duracao = duracao;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        if (typeof value !== 'string') {
            throw new Error('Invalid type for name, expected string');
        }
        this._name = value;
    }

    get atendimentoId() {
        return this._atendimentoId;
    }

    set atendimentoId(value) {
        if (typeof value !== 'string') {
            // Assuming GUID is a string
            throw new Error('Invalid type for atendimentoId, expected string');
        }
        this._atendimentoId = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        // Assuming StatusAudio is an object, replace with appropriate check if not
        if (typeof value !== 'object') {
            throw new Error('Invalid type for status, expected object');
        }
        this._status = value;
    }

    get date() {
        return this._date;
    }

    set date(value) {
        if (!(value instanceof Date)) {
            throw new Error('Invalid type for date, expected Date');
        }
        this._date = value;
    }

    get blob() {
        return this._blob;
    }

    set blob(value) {
        if (!(value instanceof Blob)) {
            throw new Error('Invalid type for blob, expected Blob');
        }
        this._blob = value;
    }

    url() {
        return URL.createObjectURL(this._blob);
    }

    get duracao() {
        return this._duracao;
    }

    set duracao(value) {
        if (typeof value !== 'number') {
            throw new Error('Invalid type for duracao, expected number');
        }
        this._duracao = value;
    }
}
