import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { saveAtendimento } from '../../services/atendimento';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { saveTokenLocalStorage } from '../../config/tokenManager';
import dayjs from 'dayjs';
const Integracao = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const token = searchParams.get('token');
    const atendimentoId = searchParams.get('atendimentoId');
    const pacienteName = searchParams.get('pacienteName');

    const [erro, setErro] = useState('');

    async function init(token) {
        try {
            saveTokenLocalStorage(token);
            setLoading(true);
            await api.get('auth');
            // if (!atendimentoId) {
            //     const descricaoAtendimento = `Atendimento criado pelo sistema externo ${dayjs().format('DD/MM [às] HH:mm')} ${
            //         pacienteName ? `para o paciente ${pacienteName}` : ''
            //     } `;
            //     const nomeAtendimento = pacienteName
            //         ? `${pacienteName} ${dayjs().format('DD/MM')}`
            //         : `Atendimento ${dayjs().format('DD/MM [às] HH:mm')}`;
            //     const atendimentoCriado = await saveAtendimento(nomeAtendimento, descricaoAtendimento);
            //     idAtendimento = atendimentoCriado.id;
            // }
            navigate(`/main/${atendimentoId}`, { replace: true, state: { externo: true } });
        } catch (error) {
            if (error?.response?.status == 401) {
                return setErro('Token inválido, favor verifique com suporte do seu sistema');
            }
            console.error(error);
            toast.error('Erro ao acessar serviço');
            setErro('Token inválido, favor verifique com suporte do seu sistema');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        init(token);
    }, []);

    if (erro) {
        return (
            <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography variant="h5" textAlign={'center'} sx={{ width: '400px' }}>
                    {erro}
                </Typography>
                ;
            </Box>
        );
    }

    return (
        <Box height={'100%'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Typography>Redirecionando</Typography>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default Integracao;
