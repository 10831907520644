import React from 'react';

import { Container } from './TrocarSenha.styles';
import { Box, Button, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { trocarSenha } from '../../services/auth';
import { toast } from 'react-toastify';
import { saveTokenLocalStorage } from '../../config/tokenManager';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function TrocarSenha() {
    const navegate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [searchParams] = useSearchParams();
    async function trocaSenha(e) {
        try {
            setLoading();
            e.preventDefault();
            const formData = new FormData(e.target);
            const { nome, email, senha, senha2 } = Object.fromEntries(formData);
            const tokenQuery = searchParams.get('token');

            if (!tokenQuery) {
                toast.error('Token não encontrado!');

                return navegate('/');
            }

            if (senha !== senha2) {
                toast.error('Senhas não conferem! Por favor, tente novamente.');
                return;
            }

            const regex = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{6,128}$/;

            if (!regex.test(senha)) {
                toast.error(
                    'A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
                );
                return;
            }

            const token = await trocarSenha(tokenQuery, senha, email);
            saveTokenLocalStorage(token);
            navegate('/main', { replace: true });
        } catch (error) {
            if (error.response.status === 401) {
                toast.error('Email ou senha inválidos');
            } else {
                toast.error('Erro ao logar');
            }
        } finally {
            setLoading(false);
        }
    }
    return (
        <Container>
            <Box component={'form'} name="cadastro" onSubmit={trocaSenha} autoComplete="off">
                <h1>Digite a nova senha!</h1>
                <TextField id="Email" name="email" label="Email" variant="outlined" type="email" required fullWidth />
                <TextField
                    id="Senha"
                    name="senha"
                    label="Senha"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    helperText="A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
                />
                <TextField
                    id="Senha2"
                    name="senha2"
                    label="Repita a Senha"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    helperText="A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
                />

                <Button variant="outlined" type="submit" fullWidth>
                    Atualizar Senha
                </Button>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default TrocarSenha;
