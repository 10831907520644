import styled from 'styled-components';

export const ArticleResumo = styled.article`
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const ContainerBlocos = styled.div`
    background: #70157f33;
    border-radius: 5px;
    padding: 10px 8px 8px 8px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;

    > h2 {
        font-size: 0.8rem;
        font-weight: 600;
        margin: 0px;
        margin-bottom: 10px;
    }
    > button {
        position: absolute;
        top: 8px;
        right: 10px;
        background: #373737;
        color: #fff;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
    }

    > p {
        font-size: 0.9rem;
        margin: 0px;
        padding: 5px;
        background-color: #fff;
    }

    > div {
        flex: 1;
        height: 100%;
        display: flex;
        background-color: #fff;
        border-radius: 5px;
        padding: 5px;
    }

    > div > textarea {
        width: 100%;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 0.9rem;
        resize: none;
        font-family: inherit;
        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #70157f33;
        }
    }
`;

export const ContainerEditor = styled.div`
    background: #70157f33;
    border-radius: 5px;
    padding: 10px;
    padding-top: 50px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;

    > button {
        position: absolute;
        top: 8px;
        right: 10px;
        background: #373737;
        color: #fff;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        padding: 5px;
        cursor: pointer;
    }

    > div {
        flex: 1;
        height: 100%;
        display: flex;
        background-color: #fff;
        border-radius: 5px;
        padding: 5px;
        overflow: hidden;
        padding-bottom: 50px;
    }
`;
