import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    > .divFilhoContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        margin: 10px;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }
    @media screen and (max-width: 800px) {
        overflow: hidden auto;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;

    > .MuiTypography-subtitle1 {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow-y: hidden;
    padding-right: 5px;

    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #cfcfcf;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const Input = styled.input`
    border: none;
    cursor: pointer;
    border: 1px solid #cccccc00;
    border-radius: 5px;

    &:hover {
        background-color: #cccccc42;
    }

    &:focus {
        background-color: #cccccc42;
        border: 1px solid #cccccc;
        cursor: text;
    }
`;
