import axios from 'axios';
import constantes from '../config/constantes';
import { getTokenLocalStorage } from '../config/tokenManager';

const api = axios.create({
    baseURL: constantes.baseUrl,
});

api.interceptors.request.use(async (config) => {
    const token = getTokenLocalStorage();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// api.interceptors.response.use(
//     async (res) => {
//         return res;
//     },
//     async (err) => {
//         try {
//             const { response, config, request } = err;
//             if (response.status == 401) {
//                 const refreshToken = getRefreshToken();
//                 const { data } = await axios.get(`${config.baseURL}RefreshToken?sessaoId=${JSON.parse(refreshToken).id}&ip=1`);
//                 setToken(data.token);
//                 config.headers.Authorization = `Bearer ${data.token}`;
//                 const response = await axios(config);
//                 return response;
//             }
//             return err;
//         } catch (error) {
//             console.error(error);
//             return error;
//         }
//     },
// );
export default api;
