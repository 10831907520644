import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;

    > form {
        background-color: #efefef85;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px #e4e4e4;
    }
`;
