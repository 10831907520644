import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

export const ContainerForm = styled.div`
    overflow-y: auto;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
`;

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: center;

    > h1 {
        font-size: 1.5rem;
        margin: 10px 0;
    }
`;
