import React from 'react';

import { Container, ContainerForm, ContainerHeader } from './Assinatura.styles';
import FormPagamento from '../../elementos/formPagamento/formPagamento';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fazVendaOpen } from '../../services/venda';
import { toast } from 'react-toastify';
import { Backdrop, CircularProgress } from '@mui/material';

function Assinatura() {
    const navegate = useNavigate();
    const [searchParams] = useSearchParams();
    const idCorpParam = searchParams.get('idCorp');
    const emailParam = searchParams.get('email');
    const nameParam = searchParams.get('nome');
    const [loading, setLoading] = React.useState(false);

    async function fazVenda(dadosForm) {
        setLoading(true);
        try {
            const responseCompra = await fazVendaOpen({ ...dadosForm, idCliente: '' });
            console.log(responseCompra);
            if (responseCompra === 'Success') {
                toast.success('Compra realizada com sucesso.');
                navegate('');
            }
        } catch (error) {
            console.error(error);
            toast.error('Não foi possível realizar a compra.', { position: 'bottom-center' });
            if (error?.response?.status == 400 && error?.response?.data?.errors) {
                const objErros = error?.response?.data?.errors;
                if (Array.isArray(objErros)) {
                    objErros.forEach((erro) => {
                        toast.warning(`${erro}`, { position: 'bottom-center' });
                    });
                    return;
                }
                const result = Object.keys(objErros).map((key) => [key, objErros[key]]);
                result.forEach((erro) => {
                    erro[1].forEach((msg) => {
                        toast.warning(`Erro: ${msg}`, { position: 'bottom-center' });
                    });
                });
            } else {
                toast.warning(
                    'Por favor verifique os dados e tente novamente. Caso o erro persista entre em contato com o suporte.',
                    { position: 'bottom-center' },
                );
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ContainerHeader>
                <h1>Assinatura R1</h1>
            </ContainerHeader>
            <ContainerForm>
                <FormPagamento
                    usuario={{
                        name: nameParam || '',
                        email: emailParam || '',
                        corporativo: idCorpParam ? { id: idCorpParam } : null,
                    }}
                    visiblePassword={idCorpParam ? false : true}
                    fazVendaProps={fazVenda}
                />
            </ContainerForm>
        </Container>
    );
}

export default Assinatura;
