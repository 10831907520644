import React from 'react';

import { Container } from './Cadastro.styles';
import { Box, Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { saveTokenLocalStorage } from '../../config/tokenManager';
import { useNavigate } from 'react-router-dom';
import { register } from '../../services/auth';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';

function Cadastro() {
    const location = useLocation();
    const navegate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        console.log('Ativando Pixel analytics');
        ReactPixel.pageView();
    }, [location]);
    async function cadastrar(e) {
        setLoading(true);
        try {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { nome, email, senha, senha2, PhoneNumber } = Object.fromEntries(formData);

            if (senha !== senha2) {
                toast.error('Senhas não conferem! Por favor, tente novamente.');
                setLoading(false);
                return;
            }

            const regex = /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{6,128}$/;

            if (!regex.test(senha)) {
                toast.error(
                    'A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial',
                );
                setLoading(false);
                return;
            }

            const token = await register(nome, email, senha, PhoneNumber);
            saveTokenLocalStorage(token);
            setLoading(false);
            try {
                console.log('Acionando evento de Regigistro no Pixel analytics e Tag Manager');
                ReactPixel.track('CompleteRegistration');
                window.dataLayer.push({
                    event: 'gtm.inscrito',
                    title: 'Inscrito',
                    price: '00,00',
                });
            } catch (error) {
                console.log('Erro ao ativar Pixel analytics');
            }
            navegate('/sucessocadastro', { replace: true });
        } catch (error) {
            if (error.response.status === 401) {
                toast.error('Email ou senha inválidos');
            } else {
                if (error?.response?.data?.errors?.length) {
                    error?.response?.data?.errors.forEach.call(error.response.data.errors, (erro) => {
                        toast.error(erro);
                    });
                    setLoading(false);
                    return;
                }
                setLoading(false);
                toast.error('Erro ao tentar criar conta!');
            }
        }
    }
    return (
        <Container>
            <Box component={'form'} name="cadastro" onSubmit={cadastrar} autoComplete="off">
                <h1>Seja bem vindo!</h1>
                <TextField
                    id="Nome"
                    name="nome"
                    label="Nome"
                    autoComplete="name"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                />
                <TextField
                    id="Email"
                    name="email"
                    autoComplete="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    fullWidth
                />
                <TextField
                    id="PhoneNumber"
                    name="PhoneNumber"
                    autoComplete="tel"
                    maxlength="11"
                    label="Celular com DDD"
                    placeholder="Digite apenas numeros sem espaços, ex: 21988888888"
                    variant="outlined"
                    inputProps={{ pattern: '[0-9]{11}', maxlength: 11 }}
                    title="Digite apenas numeros sem espaços"
                    helperText="Celular com DDD apenas numeros sem espaços"
                    type="tel"
                    required
                    fullWidth
                />
                <TextField
                    id="Senha"
                    name="senha"
                    label="Senha"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    helperText="A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
                />
                <TextField
                    id="Senha2"
                    name="senha2"
                    label="Repita a Senha"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    helperText="A senha deve conter no mínimo 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
                />

                <Button variant="outlined" type="submit" fullWidth>
                    Criar Conta
                </Button>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default Cadastro;
