import api from './api';

export async function getAtendimentos() {
    const response = await api.get('/Atendimento');
    return response.data;
}

export async function getAtendimentoById(id) {
    const response = await api.get(`/Atendimento/${id}`);
    return response.data;
}

export async function saveAtendimento(nome, descricao) {
    const response = await api.post('/Atendimento', { Nome: nome, Descricao: descricao });
    return response.data;
}

export async function updateAtendimento(nome, descricao, atendimentoId) {
    const response = await api.post('/Atendimento/update', { Nome: nome, Descricao: descricao, Id: atendimentoId });
    return response.data;
}
