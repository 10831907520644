import React, { useEffect, useRef } from 'react';
import './passoApasso.css';
import speechRecognition from '../assets/automatic-speech-recognition_updated.png';
import imgMicrofone from '../assets/microfone.jpg';
import inteligenciaArtifical from '../assets/inteligencia-artificial.png';
import textSpeech from '../assets/text-to-speech.png';
import { Button, CircularProgress, Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';
import { useMain } from '../contexts/MainContext';
import { saveAtendimento } from '../services/atendimento';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import Backdrop from '@mui/material/Backdrop';
import animation_resumoJson from '../assets/animation_resumo.json';
import FormPagamento from './formPagamento/formPagamento';
import { CloseIcon } from '../pages/apresentacao/apresentacao.style';
export default function PassoApasso() {
    const [loading, setLoading] = React.useState(false);
    const { usuario, globalIsRecording, atendimentos, carregaAtendimentos } = useMain();
    const [openFormAssinaServico, setOpenFormAssinaServico] = React.useState(false);
    const navegation = useNavigate();
    const [searchParams] = useSearchParams();
    const novoAtendimentoRef = useRef();
    async function novoAtendimento() {
        try {
            if (usuario?.corporativo?.saldo != null) {
                if (usuario?.corporativo?.saldo == 0) {
                    toast.warning('Você não possui saldo suficiente para criar uma nova consulta');
                    toast.info('Para continuar utilizando o serviço, adquira mais saldo.');
                    setOpenFormAssinaServico(true);
                    return;
                }
            } else {
                if (usuario.saldo == 0) {
                    toast.warning('Você não possui saldo suficiente para criar uma nova consulta');
                    toast.info('Para continuar utilizando o serviço, adquira mais saldo.');
                    setOpenFormAssinaServico(true);
                    return;
                }
            }
            if (globalIsRecording) throw new Error('Você não pode criar uma nova consulta enquanto estiver gravando');
            setLoading(true);
            const atendimentoCriado = await saveAtendimento(
                `${atendimentos.length + 1} - Nova Consulta`,
                'Descrição da nova consulta',
            );
            await carregaAtendimentos();
            if (searchParams.get('externo')) {
                navegation(`/main/${atendimentoCriado.id}?externo=true`);
            } else {
                navegation(`/main/${atendimentoCriado.id}`);
            }
        } catch (error) {
            toast.error('Erro ao criar nova consulta');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (searchParams.get('externo')) {
            try {
                setLoading(true);
                setTimeout(() => {
                    novoAtendimentoRef.current.click();
                    setLoading(false);
                }, 5000);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    return (
        <article id="conteudo">
            <div id="principal" style={{ opacity: searchParams.get('externo') ? '0' : '1' }}>
                <div id="containerEtapas" className="containerEtapas">
                    <div className="etapa explicando carroselPosicaoAtual" id="etapaExplicando">
                        <h2>
                            O R1 MED escuta sua consulta com seu paciente
                            <br /> e em segundos cria um registro completo para você inserir no prontuário do paciente.
                        </h2>
                        <img src={imgMicrofone} alt="microfone" />

                        <div>
                            <p>
                                Importante: a qualidade do áudio captado é fundamental para um bom resultado. Sugerimos que você
                                conecte um microfone ao seu computador.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="acoesEtapas" id="acoesEtapas">
                    <div className="acoesExplicando">
                        <Button variant="contained" ref={novoAtendimentoRef} fullWidth onClick={novoAtendimento}>
                            Iniciar Nova Consulta
                        </Button>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={openFormAssinaServico}
                onClose={() => setOpenFormAssinaServico(false)}
                PaperProps={{ sx: { maxWidth: 'unset' } }}
            >
                <DialogTitle sx={{ position: 'sticky', top: 0 }} textAlign={'center'}>
                    Assinatura
                    <CloseIcon onClick={() => setOpenFormAssinaServico(false)} />
                </DialogTitle>
                <DialogContent>
                    <FormPagamento close={() => setOpenFormAssinaServico(false)} usuario={usuario} />
                </DialogContent>
            </Dialog>
        </article>
    );
}
