import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px !important;
    height: 70px;
    position: relative;

    > canvas {
        width: 100%;
        height: 100%;
    }

    > span {
        position: absolute;
        bottom: -20px;
        font-size: 12px;
    }
`;
