import React, { useEffect, useRef, useState } from 'react';

import { ArticleResumo, ContainerBlocos, ContainerEditor } from './BlocoResumo.styles';
import { toast } from 'react-toastify';
import { Button, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateResumo as updateRequest } from '../../../../services/resumo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function BlocoResumo({ resumo }) {
    const [blocos, setBlocos] = useState([]);
    const [resumoTexto, setResumoTexto] = useState('');
    const t = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            const tx = document.getElementsByTagName('textarea');
            for (let i = 0; i < tx.length; i++) {
                resizeTextarea(tx[i]);
                // tx[i].setAttribute('style', 'height:' + tx[i].scrollHeight + 'px;overflow-y:hidden;');
            }
        }, 1000);

        console.log('Blocos', blocos);
    }, [blocos]);

    useEffect(() => {
        try {
            const resumoTexto = resumo.texto;
            if (resumoTexto) {
                setResumoTexto(resumoTexto);
            }

            const resumoJson = JSON.parse(resumo.json);
            setBlocos([...resumoJson.informacoes]);
        } catch (e) {
            console.error(e);
            console.error('Erro ao carregar resumo');
        }
    }, [resumo]);

    function copiarTexto(texto) {
        navigator.clipboard.writeText(texto).then(
            function () {
                toast.success('Texto copiado');
            },
            function (err) {
                toast.error('Erro ao copiar texto');
            },
        );
    }

    function save(texto, index) {
        clearTimeout(t.current);
        t.current = setTimeout(function () {
            setBlocos((prev) => {
                const newBlocos = [...prev];
                newBlocos[index].texto = texto;
                updateResumo(resumo, newBlocos);
                return newBlocos;
            });
        }, 2000);
    }

    function saveTexto() {
        clearTimeout(t.current);
        t.current = setTimeout(function () {
            updateResumo(resumo, blocos);
        }, 2000);
    }

    async function updateResumo(resumo, blocos) {
        try {
            console.log('c% Resumo atualizando', 'color: #f00');
            const resumoResponse = await updateRequest({
                ...resumo,
                texto: resumoTexto,
                json: blocos.length > 0 ? JSON.stringify({ informacoes: blocos }) : '',
            });
            console.log('Resumo atualizado', resumoResponse);
        } catch (error) {
            toast.error('Erro ao salvar resumo');
            console.error(error);
        }
    }

    function resizeTextarea(target) {
        target.style.height = 'auto';
        target.style.height = target.scrollHeight + 'px';
        // target.setAttribute('style', 'height:' + target.scrollHeight + 'px;overflow-y:hidden;');
    }

    if (blocos.length === 0 && resumoTexto != '')
        return (
            <ArticleResumo>
                <ContainerEditor>
                    <Button size="small" variant="contained" color="primary" onClick={() => copiarTexto(resumoTexto)}>
                        Copíar
                    </Button>
                    <div>
                        <ReactQuill
                            value={resumoTexto}
                            onChange={(e) => {
                                setResumoTexto(e);
                                saveTexto(e);
                            }}
                        />
                    </div>
                    {/* <p dangerouslySetInnerHTML={{ __html: resumoTexto }}></p> */}
                </ContainerEditor>
            </ArticleResumo>
        );

    return (
        <ArticleResumo>
            {blocos.map((bloco, index) => (
                <ContainerBlocos key={index}>
                    <IconButton variant="contained" onClick={() => copiarTexto(bloco.texto)}>
                        <ContentCopyIcon sx={{ fontSize: '14px' }} />
                    </IconButton>

                    <h2>{bloco.titulo}</h2>
                    <div>
                        <textarea
                            onFocus={(e) => {
                                resizeTextarea(e.target);
                            }}
                            onChange={(e) => {
                                console.log('Bloco', e);
                                save(e.target.value, index);
                                resizeTextarea(e.target);
                            }}
                            defaultValue={bloco.texto}
                        ></textarea>
                    </div>
                </ContainerBlocos>
            ))}
        </ArticleResumo>
    );
}

export default BlocoResumo;
