import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${(props) => (props.isSmallScreen ? 'column' : 'row')};
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    /* background-image: url('https://images.pexels.com/photos/1072179/pexels-photo-1072179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'); */
    overflow: hidden;
`;
