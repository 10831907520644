import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Container, Propaganda, ContainerOptions } from './login.styles';
import { Box, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { login } from '../../services/auth';
import { saveTokenLocalStorage } from '../../config/tokenManager';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../assets/r1medlogo_small.png';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
function Login() {
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);
    const navegate = useNavigate();

    async function logar(e) {
        setLoading(true);
        try {
            e.preventDefault();
            const formData = new FormData(e.target);
            const { email, senha } = Object.fromEntries(formData);

            if (!email) {
                setLoading(false);
                return toast.error('Email é obrigatório');
            }
            if (!senha) {
                setLoading(false);
                return toast.error('Senha é obrigatório');
            }

            const token = await login(email, senha);
            saveTokenLocalStorage(token);
            setLoading(false);
            navegate('/main', { replace: true });
        } catch (error) {
            if (error.response.status === 401) {
                toast.error('Email ou senha inválidos');
            } else {
                toast.error('Erro ao logar');
            }
        }
        setLoading(false);
    }

    React.useEffect(() => {
        console.log('Ativando Pixel analytics');
        ReactPixel.pageView();

        console.log('Ativando Google analytics.');
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return (
        <Container>
            <Propaganda>
                <p className="item-1">
                    Aumente a produtividade da sua clínica, seu faturamento, ganhe uma robusta segurança jurídica e veja a
                    satisfação dos seus pacientes crescer.
                </p>

                <p className="item-2">
                    O R1 é uma ferramenta inovadora que combina inteligência artificial conversacional com o mais moderno da
                    inteligência artificial generativa.
                </p>

                <p className="item-3">
                    O R1 é o copiloto do médico: ele escuta a história completa do paciente, e em poucos segundos, converte em
                    resumos clínicos estruturados, imediatamente disponíveis para revisão e edição.
                </p>
            </Propaganda>
            <ContainerOptions>
                <Box component={'form'} name="login" onSubmit={logar}>
                    <Box display={'flex'} width={'100%'} flex={1}>
                        <img className="logo" src={logo} alt="logo" />
                    </Box>
                    <h1>Olá, vamos comerçar?</h1>
                    <TextField
                        id="Email-login"
                        name="email"
                        autoComplete="email"
                        label="Email"
                        variant="outlined"
                        type="email"
                        required
                        fullWidth
                    />
                    <TextField
                        id="Senha-login"
                        name="senha"
                        label="Senha"
                        variant="outlined"
                        type="password"
                        required
                        fullWidth
                    />
                    <Link to="/esqueciSenha">Esqueci minha senha</Link>
                    <Button variant="contained" type="submit" fullWidth>
                        Logar
                    </Button>
                    <Button variant="contained" onClick={() => navegate('/cadastro')} fullWidth>
                        Criar Nova Conta
                    </Button>
                </Box>
                <Box>
                    <Typography variant="caption" children={'(c) 2023 - 2024 R1 MED Tecnologia'} />
                </Box>
            </ContainerOptions>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={() => setLoading(false)}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}

export default Login;
