import React, { useEffect, useMemo } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    useTheme,
} from '@mui/material';

import { Container } from './EspecialidadesDialog.style.js';
import { getEspecialidades } from '../../services/especialidades.js';
import { toast } from 'react-toastify';
import { vinculaEspecialidadeENumConsultaMes } from '../../services/usuario.js';
import { useMain } from '../../contexts/MainContext.js';

function EspecialidadesDialog({ close }) {
    const { usuario } = useMain();
    const especialidadeUsuario = useMemo(() => {
        return usuario?.especialidades
            ? usuario.especialidades.map((x) => ({ id: x.especialidade.id, name: x.especialidade.name }))
            : [];
    }, [usuario]);
    const [especialidades, setEspecialidades] = React.useState([]);
    const [especialidadesSelecionadas, setEspecialidadesSelecionadas] = React.useState([]);
    const [consultasMes, setConsultasMes] = React.useState(0);

    async function init() {
        const especialidades = await getEspecialidades();
        if (especialidades) setEspecialidades(especialidades);
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setEspecialidadesSelecionadas(especialidadeUsuario);
    }, [especialidadeUsuario]);

    useEffect(() => {
        setConsultasMes(usuario?.mediaConsultaMes);
    }, [usuario]);

    useEffect(() => {
        console.log(especialidadesSelecionadas);
    }, [especialidadesSelecionadas]);

    async function salvar() {
        if (!especialidadesSelecionadas.length) {
            toast.error('Selecione ao menos uma especialidade!');
            return;
        }

        if (consultasMes <= 0) {
            toast.error('Informe a media de consultas que realiza por mês!');
            return;
        }

        try {
            const result = await vinculaEspecialidadeENumConsultaMes(
                especialidadesSelecionadas.map((esp) => esp.id),
                consultasMes,
            );
            console.log(result);
        } catch (error) {
            console.log(error);
        } finally {
            close();
        }
    }

    return (
        <Container>
            <DialogTitle id="alert-dialog-title">Nos ajude a melhorar sua experiência no R1</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Para que possamos melhorar sua experiência no R1, precisamos saber quais são suas especialidades. E uma média
                    de quantos atendimentos você realiza por mês.
                </DialogContentText>

                <Box display={'flex'} flexDirection={'column'} gap={'20px'} marginTop={'30px'}>
                    <Box>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={especialidades}
                            getOptionLabel={(option) => option.name}
                            getOptionKey={(option) => option.id}
                            value={especialidadesSelecionadas}
                            onChange={(event, newValue) => {
                                setEspecialidadesSelecionadas(newValue);
                            }}
                            size="small"
                            renderInput={(params) => (
                                <TextField {...params} label="Especialidades" placeholder="Especialidades" />
                            )}
                        />
                    </Box>
                    <Box>
                        <TextField
                            type="number"
                            size="small"
                            fullWidth
                            value={consultasMes}
                            onChange={(e) => setConsultasMes(e.target.value)}
                            inputProps={{ max: 200, min: 0 }}
                            label="Consultas Mês"
                            placeholder="Consultas Mês"
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={salvar} autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Container>
    );
}

export default EspecialidadesDialog;
