import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RouterProvider } from 'react-router-dom';
import rotas from './rotas';
import { lightThemeMui } from './config/themes/light.theme';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

window.origin.includes('localhost') ? (window.document.title = 'R1 Localhost') : (window.document.title = 'R1');
const root = ReactDOM.createRoot(document.getElementById('root'));

TagManager.initialize({
    gtmId: 'GTM-WQHN5FQM',
});
ReactPixel.init('1135477370810918');

root.render(
    <ThemeProvider theme={lightThemeMui}>
        <div className="App">
            <RouterProvider router={rotas} />
        </div>
        <ToastContainer theme="colored" pauseOnFocusLoss pauseOnHover />
    </ThemeProvider>,
);
