import React, { useCallback, useEffect, useRef, useState } from 'react';
import { set } from 'react-ga';
import { toast } from 'react-toastify';
const sampleRates = { min: 11025, ideal: 44100, max: 48000 };
const maxFileSizeBytes = 6 * 1024 * 128;
const bufferSize = 2048;
const AudioContext = window.AudioContext || window.webkitAudioContext;

export class ErrorMicrofone extends Error {
    constructor(message) {
        super(message);
        this.name = 'ErrorMicrofone';
    }
}

const solicitaPermissaoMicrofone = function () {
    return new Promise((resolve, reject) => {
        const midiaDispositivos = navigator.mediaDevices;

        if (midiaDispositivos) {
            midiaDispositivos
                .getUserMedia({ audio: true, video: false })
                .then(function (stream) {
                    stream.getTracks().forEach((track) => track.stop());
                    resolve(midiaDispositivos.enumerateDevices());
                })
                .catch(function (err) {
                    if (err?.message == 'Permission denied') {
                        console.error(err, 'Permissão negada para acessar o microfone');
                        reject(
                            new ErrorMicrofone(
                                'Permissão negada para acessar o microfone, por favor verifique as configurações do seu navegador',
                            ),
                        );
                    }
                    console.error(err);
                    reject(err);
                });
        } else {
            console.error('Seu navegador não suporta a API de Dispositivos de Mídia');
            reject('Seu navegador não suporta a API de Dispositivos de Mídia');
        }
    });
};

export const LIMITE_SOM_BAIXO = 5;
export const TEMPO_LIMITE_SOM_BAIXO = 10000;

function useMic() {
    const recorderInfos = useRef({ leftchannel: [], rightchannel: [], recordingLength: 0 });
    const [stream, setStream] = useState(null);
    const [wakeLockActive, setWakeLockActive] = useState(false);
    const drawVisual = useRef(null);
    const context = useRef(null);
    const wekelock = useRef(null);
    const timelineVolume = useRef([]);
    const fnVerificardorDeVolume = useRef(null);
    const [somBaixo, setSomBaixo] = useState('');
    const alertaSomMuitoBaixo = useRef([]);
    const [analyserCopy, setAnalyserCopy] = useState(null);

    async function getStream(constraints) {
        if (!constraints) {
            constraints = { audio: true, video: false };
        }
        return await navigator.mediaDevices.getUserMedia(constraints);
    }

    const startRecording = useCallback(
        async (deviceId, callback, canvas) => {
            if (stream) throw new Error('Já existe uma gravação em andamento');

            if (!deviceId) throw new Error('Não foi possível identificar o dispositivo de áudio');

            if (!AudioContext) {
                throw new Error('Seu navegador não suporta a API de Dispositivos de Mídia');
            }

            clearRecordInfo();

            const optionsMidiaDevice = {
                audio: { deviceId: { exact: deviceId } },
                video: false,
            };
            await solicitaPermissaoMicrofone();
            const newStream = await getStream(optionsMidiaDevice);
            setStream(newStream);
            if ('wakeLock' in navigator) {
                console.log(
                    'O navegador suporta a funcionalidade de prevenção de bloqueio automático da tela. Ativando Wake Lock',
                );
                preventLoke();
            }
            await recordering(callback, newStream, false, canvas);

            try {
                if (!Array.isArray(timelineVolume.current)) throw new Error('timelineVolume.current não é um array');
                fnVerificardorDeVolume.current = setInterval(() => {
                    const volumeMedio = timelineVolume.current.reduce((a, b) => a + b, 0) / timelineVolume.current.length;
                    console.log(`Volume médio do microfone dos ultimos ${TEMPO_LIMITE_SOM_BAIXO / 1000}s:`, volumeMedio);
                    timelineVolume.current = [];
                    if (volumeMedio < LIMITE_SOM_BAIXO) {
                        if (volumeMedio < 1) {
                            alertaSomMuitoBaixo.current.push(volumeMedio);
                            if (alertaSomMuitoBaixo.current.length >= 12) {
                                alertaSomMuitoBaixo.current = [];
                                const mensagem =
                                    'A captação do microfone está muito baixa, por favor verifique se o microfone está conectado corretamente. Ou se a sua distancia e do pociante em relação ao microfone está correta.';

                                if ('Notification' in window && Notification.permission === 'granted') {
                                    new Notification(mensagem);
                                } else {
                                    toast.error(mensagem);
                                }
                            }
                        }
                        if (volumeMedio < 1) {
                            setSomBaixo('Pouco ou nenhum audio esta sendo captado');
                        } else {
                            setSomBaixo('Som do microfone muito baixo! Fale mais alto ou chegue perto do microfone!');
                        }
                    } else {
                        alertaSomMuitoBaixo.current = [];
                        setSomBaixo('');
                    }
                }, TEMPO_LIMITE_SOM_BAIXO);
            } catch (error) {
                console.error('Erro ao tentar captar volume medio do fragmento no startTestRecording', error);
            }
        },
        [stream],
    );

    const startTestRecording = useCallback(
        async (deviceId) => {
            if (stream) throw new Error('Já existe uma gravação em andamento');

            if (!deviceId) throw new Error('Não foi possível identificar o dispositivo de áudio');

            if (!AudioContext) {
                throw new Error('Seu navegador não suporta a API de Dispositivos de Mídia');
            }

            clearRecordInfo();

            const optionsMidiaDevice = {
                audio: { deviceId: { exact: deviceId } },
                video: false,
            };
            await solicitaPermissaoMicrofone();
            const newStream = await getStream(optionsMidiaDevice);
            setStream(newStream);
            recordering(null, newStream, true, null);
        },
        [stream],
    );

    const stopRecording = useCallback(
        async (canvas) => {
            const { leftchannel, rightchannel, recordingLength } = recorderInfos.current;
            const blob = await gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, context.current.sampleRate);
            stream.getTracks().forEach((track) => track.stop());
            setStream(null);

            if (context.current) {
                context.current.close();
                context.current = null;
            }

            clearRecordInfo();
            try {
                if (drawVisual.current) window.cancelAnimationFrame(drawVisual.current);
                if (canvas) {
                    const context = canvas.getContext('2d');
                    context.clearRect(0, 0, canvas.width, canvas.height);
                }
            } catch (error) {
                console.log('Não foi possível limpar o canvas');
                console.error(error);
            }
            if ('wakeLock' in navigator) {
                console.log('Desativando Wake Lock');
                releaseWakeLock();
            }

            try {
                if (fnVerificardorDeVolume.current) {
                    clearInterval(fnVerificardorDeVolume.current);
                    fnVerificardorDeVolume.current = null;
                    console.log('Limpando intervalo de verificação de volume');
                }
                if (Array.isArray(timelineVolume.current)) {
                    timelineVolume.current = [];
                    console.log('Limpando array de volume medio');
                }
            } catch (error) {
                console.error('Erro ao tentar zerar array de volume medio no stopRecording', error);
            }

            setAnalyserCopy(null);

            return blob;
        },
        [stream],
    );

    const clearRecordInfo = () => {
        recorderInfos.current = { leftchannel: [], rightchannel: [], recordingLength: 0 };
    };

    const recordering = useCallback(
        async (callback, stream, isTest = false, canvas = null) => {
            try {
                context.current = new AudioContext({ sampleRate: sampleRates.min });
                const sampleRate = context.current.sampleRate;
                const volume = context.current.createGain();
                const audioInput = context.current.createMediaStreamSource(stream);
                const analyser = context.current.createAnalyser();
                audioInput.connect(analyser);
                const recorder = context.current.createScriptProcessor(bufferSize, 2, 2);
                analyser.connect(recorder);
                recorder.connect(context.current.destination);
                setAnalyserCopy(analyser);
                recorder.onaudioprocess = async function (e) {
                    try {
                        const volumeMedio = microphoneVolumeMedio(analyser);
                        timelineVolume.current.push(volumeMedio);
                    } catch (error) {
                        console.error('Erro ao tentar captar volume medio do fragmento', error);
                    }
                    console.log('%c 🔴 Gravando... 🔴', 'color:#F3B95F');
                    const left = e.inputBuffer.getChannelData(0);
                    const right = e.inputBuffer.getChannelData(1);

                    recorderInfos.current.leftchannel.push(new Float32Array(left));
                    recorderInfos.current.rightchannel.push(new Float32Array(right));
                    recorderInfos.current.recordingLength += bufferSize;

                    if (recorderInfos.current.recordingLength >= maxFileSizeBytes && !isTest) {
                        if (!callback || typeof callback != 'function')
                            return console.error('%c 🔴 Callback não definido ou não é uma função 🔴', 'color:#F3B95F');
                        console.log('%c 🔴 Limite de tamanho de arquivo atingido. Gerando gravação... 🔴', 'color:#F3B95F');
                        const { leftchannel, rightchannel, recordingLength } = recorderInfos.current;
                        clearRecordInfo();
                        // gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, sampleRate).then((blob) => {
                        //     console.log('blobURL gerado pelo limite de tamanho.', blob);

                        //     callback(blob);
                        // });

                        const blob = await gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, sampleRate);
                        console.log('%c 🔴 blobURL gerado pelo limite de tamanho. 🔴', 'color:#F3B95F', blob);
                        console.log('%c 🔴 ------------------------------------------------------ 🔴', 'color:#F3B95F');
                        console.log('%c 🔴 chamando callback dentro do recordering', 'color:#F3B95F');
                        await callback(blob);
                        console.log('%c 🔴 callback dentro do recordering foi  finalizado 🔴', 'color:#F3B95F');
                    }
                };
                if (canvas && !isTest) visualizeFrequencybars(analyser, canvas);
            } catch (error) {
                console.log('%c 🔴 Erro na execução do recordering 🔴', 'color:#F3B95F');
                console.error(error);
            }
        },
        [stream],
    );

    function microphoneVolumeMedio(analyser) {
        let array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        let values = 0;

        let length = array.length;
        for (let i = 0; i < length; i++) {
            values += array[i];
        }

        let average = values / length;

        // Considera-se que o microfone está mudo se o valor médio for muito baixo
        return average;
    }

    function visualizeFrequencybars(analyser, canvas) {
        if (!canvas) {
            const mensagem = 'Iniciar Frequencybars pois o objeto canvas não foi encontrado.';
            console.error(mensagem);
            return toast.error(mensagem);
        }
        const canvasCtx = canvas.getContext('2d');

        let WIDTH = canvas.width;
        let HEIGHT = canvas.height;
        let CENTERX = canvas.width / 2;
        let CENTERY = canvas.height / 2;

        if (!analyser) return toast.error('erro', 'Impossivel processar Frequencybars pois o mesmo não esta sendo gravado.');

        analyser.fftSize = 64;
        var bufferLengthAlt = analyser.frequencyBinCount;
        var dataArrayAlt = new Uint8Array(bufferLengthAlt);

        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        const drawAlt = function () {
            drawVisual.current = requestAnimationFrame(drawAlt);

            analyser.getByteFrequencyData(dataArrayAlt);

            canvasCtx.fillStyle = 'rgb(229 186 237)';
            canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

            var barWidth = WIDTH / bufferLengthAlt;
            var barHeight;
            var x = 0;
            for (var i = 0; i < bufferLengthAlt; i++) {
                barHeight = dataArrayAlt[i];

                // canvasCtx.fillStyle = 'rgb(' + (barHeight + 100) + ',82,82)';
                canvasCtx.fillStyle = 'rgb(110 40 40)';
                canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);

                x += barWidth + 1;
            }
        };

        drawAlt();
    }

    function preventLoke() {
        navigator.wakeLock
            .request('screen')
            .then((wakeLock) => {
                setWakeLockActive(true);
                wekelock.current = wakeLock;
                console.log('Wake Lock ativo');
            })
            .catch((err) => {
                console.error(`${err.name}, ${err.message}`);
                console.error('Ocorreu algum erro ao ativar o Wake Lock');
            });
    }

    function releaseWakeLock() {
        wekelock?.current
            ?.release('screen')
            .then(() => {
                console.log('Wake Lock liberado');
                wekelock.current = null;
                setWakeLockActive(false);
            })
            .catch((err) => {
                console.error(`${err.name}, ${err.message}`);
                console.error('Ocorreu algum erro ao liberar o Wake Lock');
            });
    }

    return {
        isRecording: !!stream,
        startRecording,
        stopRecording,
        solicitaPermissaoMicrofone,
        startTestRecording,
        wakeLockActive,
        somBaixo,
        analyser: analyserCopy,
    };
}

export default useMic;

async function gerarArquivoDaGravacao(leftchannel, rightchannel, recordingLength, sampleRate) {
    console.log('gerando arquivo de gravação');
    function mergeBuffers(channelBuffer, recordingLength) {
        let result = new Float32Array(recordingLength);
        let offset = 0;
        let lng = channelBuffer.length;
        for (let i = 0; i < lng; i++) {
            let buffer = channelBuffer[i];
            result.set(buffer, offset);
            offset += buffer.length;
        }
        return result;
    }

    function interleave(leftChannel, rightChannel) {
        let length = leftChannel.length + rightChannel.length;
        let result = new Float32Array(length);

        let inputIndex = 0;

        for (let index = 0; index < length; ) {
            result[index++] = leftChannel[inputIndex];
            result[index++] = rightChannel[inputIndex];
            inputIndex++;
        }
        return result;
    }

    function writeUTFBytes(view, offset, string) {
        let lng = string.length;
        for (let i = 0; i < lng; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }

    // we flat the left and right channels down
    let leftBuffer = mergeBuffers(leftchannel, recordingLength);
    let rightBuffer = mergeBuffers(rightchannel, recordingLength);

    // we interleave both channels together
    let interleaved = interleave(leftBuffer, rightBuffer);

    // we create our wav file
    let buffer = new ArrayBuffer(44 + interleaved.length * 2);
    let view = new DataView(buffer);

    // RIFF chunk descriptor

    writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, 44 + interleaved.length * 2, true);
    writeUTFBytes(view, 8, 'WAVE');
    // FMT sub-chunk
    writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    // stereo (2 channels)
    view.setUint16(22, 2, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 4, true);
    view.setUint16(32, 4, true);
    view.setUint16(34, 16, true);
    // data sub-chunk
    writeUTFBytes(view, 36, 'data');
    view.setUint32(40, interleaved.length * 2, true);

    // write the PCM samples
    let lng = interleaved.length;
    let index = 44;
    let volume = 1;
    for (let i = 0; i < lng; i++) {
        view.setInt16(index, interleaved[i] * (0x7fff * volume), true);
        index += 2;
    }

    // our final binary blob
    const blob = new Blob([view], { type: 'audio/mp3' });
    // const urlArquivoAudio = URL.createObjectURL(blob);
    console.log('retornando blob gravação');
    return blob;
}
