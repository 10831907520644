import React, { useCallback, useEffect, useState } from 'react';
import { Container } from './main.styles';
import MenuLateral from './menuLateral/MenuLateral';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { MainProvider } from '../../contexts/MainContext';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useMediaQuery, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import EspecialidadesDialog from '../../elementos/especialidadesDialog/EspecialidadesDialog';
import WarningImg from '../../assets/main/atencao.png';
import { useSearchParams } from 'react-router-dom';

function Main() {
    const location = useLocation();
    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const [popupEspecialidade, setPopupEspecialidade] = useState(false);
    const [openDialogAvisoWakeLock, setOpenDialogAvisoWakeLock] = useState(false);
    const [isExternal, setIsExternal] = useState(false);

    useEffect(() => {
        console.log('Ativando Pixel analytics');
        ReactPixel.pageView();

        console.log('Ativando Google analytics.');
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    useEffect(() => {
        const isExternal = location.state?.externo;
        if (isExternal) {
            console.log('Integração externa detectada.');
            setIsExternal(true);
        }
        const solicitaEspecialidade = location.state?.solicitaEspecialidade;
        if (solicitaEspecialidade) setPopupEspecialidade(true);
        if ('wakeLock' in navigator) {
            console.log('O navegador suporta a funcionalidade de prevenção de bloqueio automático da tela.');
        } else {
            setOpenDialogAvisoWakeLock(true);
        }
    }, []);

    return (
        <MainProvider>
            <Container isSmallScreen={isSmallScreen}>
                {!isExternal && <MenuLateral />}
                <Outlet context={[]} />
                <Dialog
                    open={popupEspecialidade}
                    // onClose={() => setPopupEspecialidade(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <EspecialidadesDialog close={() => setPopupEspecialidade(false)} />
                </Dialog>
                <Dialog open={openDialogAvisoWakeLock} onClose={() => setOpenDialogAvisoWakeLock(false)}>
                    <DialogTitle alignItems={'center'} justifyContent={'center'} display={'flex'}>
                        <img style={{ marginRight: '16px', height: '20px', width: '20px' }} src={WarningImg} alt="" />
                        Aviso Importante!
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p>
                                Informamos que o seu navegador atual não suporta a funcionalidade de prevenção de bloqueio
                                automático da tela, usada para evitar a interrupção da gravação de áudio quando o dispositivo não
                                está sendo usado.
                            </p>
                            <p>
                                Recomendamos ajustar essa configuração diretamente no sistema operacional do seu dispositivo para
                                uma experiência ideal.
                            </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button fullWidth variant="contained" onClick={() => setOpenDialogAvisoWakeLock(false)}>
                            Estou ciente
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </MainProvider>
    );
}

export default Main;
